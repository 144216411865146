import React, { useEffect } from "react";
import "../index.css";
import { Link } from "react-router-dom";
const ApptCall = () => {
  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img class=" w-15" src="../../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-card p-0 container-340 ">
          <div className="help-card-bottom help-card-call-bottom position-relative ">
          <div className="position-absolute">
                <img className="image-set" height="50" width="50" src="/ICON_PHONE.svg"></img>
              </div>
            <div className="text-white card-help-body card-thanks">
             <p className="mb-2 normal_text">
                Great! Call us to schedule a phone visit by clicking the button
                below or dialing the phone number.
              </p>
              <p className="mb-2 normal_text">
                Please call us during these hours:
              </p>
              <p className="mb-0 normal_text">
                Monday - Thursday, 8:00am to 5:30pm
              </p>
              <p className="mb-0 normal_text">Friday 8:00am to 5:00pm.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-2">
            <div className="btn_global global-button">
              <span className="mb-0">
                <Link to={`tel:1-866-380-2588`}>1-866-380-2588</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApptCall;
