import moment from "moment";
import { jwtDecode } from "jwt-decode";


export const formatDate = (date) => {
  if (date) {
    const dateData = moment(date);
    const formattedDate = dateData.format("dddd Do MMMM YYYY");
    const formattedDateWithSuperscript = formattedDate.replace(
      /(\d+)(st|nd|rd|th)/,
      "$1<sup>$2</sup>"
    );
    return formattedDateWithSuperscript;
  } else {
    return "";
  }
};

export const decodeToken = (token) => {
  if (token) {
    try {
      const user = jwtDecode(token);
      // console.log("user",user);
      return user
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
}