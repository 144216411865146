import React, { useEffect, useRef, useState } from "react";
import './helpers/style.css'
import PlayIcon from '../../../assets/images/playIcon.png'

const Video = ({ data, handleSave, getUserCampData }) => {
  const { values } = data;
  const campVideoRef = useRef([]);
  const [isPlaying, setIsPlaying] = useState({});

  const watchedIds = getUserCampData?.responseDesc?.filter(item => item?.result === "WATCHED")?.map(item => item?.id) || [];

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item,) => {

    const marginTop = item?.marginTop || 0;
    const marginBottom = item?.marginBottom || 0;
    const marginLeft = item?.marginLeft || 0;
    const marginRight = item?.marginRight || 0;

    const baseStyles = {
      // position: item?.align ? "absolute" : "relative",
      position: "relative",
      zIndex: 0,
      height: isFitToScreen ? "600px" : backgroundHeight,
      width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`
    };

    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          transform: "translateY(0)",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      default:
        return baseStyles;
    }
  };

  const handlePlayPause = (item, index) => {
    const videoElement = campVideoRef.current[index];
    if (videoElement) {
      if (isPlaying[index]) {
        videoElement?.pause();
        setIsPlaying((prev) => ({ ...prev, [index]: false }));
      } else {
        if (videoElement?.requestFullscreen) {
          videoElement?.requestFullscreen();
        } else if (videoElement?.mozRequestFullScreen) {
          videoElement?.mozRequestFullScreen();
        } else if (videoElement?.webkitRequestFullscreen) {
          videoElement?.webkitRequestFullscreen();
        } else if (videoElement?.msRequestFullscreen) {
          videoElement?.msRequestFullscreen();
        }
        videoElement?.play();
        setIsPlaying((prev) => ({ ...prev, [index]: true }));
        handleSave({
          parentId: data?.id,
          id: String(item?.id),
          question: "Watched video",
          result: "WATCHED"
        },
          data?.type,
          false
        );
      }
    }
  };

  useEffect(() => {
    values?.forEach((item, index) => {
      const videoElement = campVideoRef.current[index];
      if (videoElement) {
        videoElement?.addEventListener('pause', () => {
          setIsPlaying((prev) => ({ ...prev, [index]: false }));
        });
      }
    });
  }, [values]);


  return (
    <div 
    //  className="control_padding"
     >
      <>
        <React.Fragment>
          {values &&
            values.length > 0 &&
            values?.map((item, ind) => {
              const isWatched = watchedIds?.includes(String(item?.id));
              return (
                  <>
                    <div
                      style={{
                        ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, item?.type, false),
                        width: "100%"
                      }}
                      >
                        <div className="d-flex" 
                          style={{border: item.videoUrl ? (`1px solid ${item?.borderColor ? item?.borderColor : "#000"}`) : "", padding: "5px", borderRadius: item?.borderCorner, flexDirection: item?.videoPosition === "left" ? 'row-reverse' : "", width: "100%"}}
                        >
                      <div style={{ width: "65%" }}>
                        <div style={{
                          marginTop: item?.headerMarginTop ? `${item?.headerMarginTop}px` : "",
                          marginLeft: item?.headerMarginLeft ? `${item?.headerMarginLeft}px` : '',
                          marginRight: item?.headerMarginRight ? `${item?.headerMarginRight}px` : "",
                          marginBottom: item?.headerMarginBottom ? `${item?.headerMarginBottom}px` : ""
                        }}>
                          <p className="mb-0" style={{
                            textOverflow: "ellipsis", color: item?.headerTextColor,
                            overflow: "hidden", textWrap: "nowrap",
                            paddingTop: item?.headerPaddingTop ? `${item?.headerPaddingTop}px` : "",
                            paddingRight: item?.headerPaddingRight ? `${item?.headerPaddingRight}px` : "1rem",
                            paddingLeft: item?.headerPaddingLeft ? `${item?.headerPaddingLeft}px` : "",
                            paddingBottom: item?.headerPaddingBottom ? `${item?.headerPaddingBottom}px` : "",
                            fontSize: (item?.headerTextSize && item?.headerTextSize !== "px") ? item?.headerTextSize : "1.2rem",
                            fontFamily: item?.headerFont
                          }}
                            dangerouslySetInnerHTML={{ __html: item?.videoTitle }} >

                          </p>
                        </div>
                        <div>
                          <p className="text_clamp" dangerouslySetInnerHTML={{ __html: item?.videoDesc }}
                            style={{
                              color: item?.bodyTextColor,
                              fontSize: (item?.bodyTextSize && item?.bodyTextSize !== "px") ? item?.bodyTextSize : "10px",
                              lineHeight: "",
                              marginTop: item?.bodyTextMarginTop ? `${item?.bodyTextMarginTop}px` : "",
                              marginLeft: item?.bodyTextMarginLeft ? `${item?.bodyTextMarginLeft}px` : '',
                              marginRight: item?.bodyTextMarginRight ? `${item?.bodyTextMarginRight}px` : "",
                              marginBottom: item?.bodyTextMarginBottom ? `${item?.bodyTextMarginBottom}px` : "",
                              paddingTop: item?.bodyTextPaddingTop ? `${item?.bodyTextPaddingTop}px` : "",
                              paddingRight: item?.bodyTextPaddingRight ? `${item?.bodyTextPaddingRight}px` : "",
                              paddingLeft: item?.bodyTextPaddingLeft ? `${item?.bodyTextPaddingLeft}px` : "",
                              paddingBottom: item?.bodyTextPaddingBottom ? `${item?.bodyTextPaddingBottom}px` : "",
                              fontFamily: item?.bodyTextFont
                            }}></p>
                        </div>
                        {
                          item?.iconPosition !== "right" && item?.newTag === "yes" ?
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {item?.iconImage &&
                                <div style={{
                                  width: "100%",
                                  display: 'flex',
                                  justifyContent: item?.iconPosition !== "left" ? "flex-end" : "space-between",
                                  alignItems: 'center'
                                }}>
                                  <img alt="logo"
                                    src={item?.iconImage}
                                    style={{
                                      height: "auto",
                                      width: item?.iconSize ? `${item?.iconSize}px` : "2rem",
                                      marginTop: item?.iconMarginTop ? `${item?.iconMarginTop}px` : "",
                                      marginLeft: item?.iconMarginLeft ? `${item?.iconMarginLeft}px` : '',
                                      marginRight: item?.iconMarginRight ? `${item?.iconMarginRight}px` : "",
                                      marginBottom: item?.iconMarginBottom ? `${item?.iconMarginBottom}px` : "",
                                    }}
                                  />
                                </div>
                              }
                              {!isWatched && item?.newTag === "yes" &&
                                <div style={{
                                  backgroundColor: item?.newTagColor, paddingLeft: 8, paddingRight: 8, borderRadius: 5,
                                  marginRight: item?.tagMarginRight ? `${item?.tagMarginRight}px` : "",
                                  marginLeft: item?.tagMarginLeft ? `${item?.tagMarginLeft}px` : "",
                                  marginTop: item?.tagMarginTop ? `${item?.tagMarginTop}px` : "",
                                  marginBottom: item?.tagMarginBottom ? `${item?.tagMarginBottom}px` : "",
                                  display: "flex", alignItems: "center"
                                }}>
                                  <p style={{ color: '#fff', fontSize: item?.newTagSize ? `${item?.newTagSize}px` : "0.8rem" }}>New</p>
                                </div>
                              }
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {
                                !isWatched && item?.newTag === "yes" &&
                                <div style={{
                                  backgroundColor: item?.newTagColor, paddingLeft: 8, paddingRight: 8, borderRadius: 5,
                                  marginRight: item?.tagMarginRight ? `${item?.tagMarginRight}px` : "",
                                  marginLeft: item?.tagMarginLeft ? `${item?.tagMarginLeft}px` : "",
                                  marginTop: item?.tagMarginTop ? `${item?.tagMarginTop}px` : "",
                                  marginBottom: item?.tagMarginBottom ? `${item?.tagMarginBottom}px` : "",
                                  display: "flex", alignItems: "center"
                                }}>
                                  <p style={{ color: '#fff', fontSize: item?.newTagSize ? `${item?.newTagSize}px` : "0.8rem" }}>New</p>
                                </div>
                              }
                              {item?.iconImage &&
                                <div style={{
                                  width: "100%",
                                  display: 'flex',
                                  justifyContent: item?.iconPosition !== "left" ? "flex-end" : "space-between",
                                  alignItems: 'center'
                                }}>
                                  <img alt="logo"
                                    src={item?.iconImage}
                                    style={{
                                      height: "auto",
                                      width: item?.iconSize ? `${item?.iconSize}px` : "2rem",
                                      marginTop: item?.iconMarginTop ? `${item?.iconMarginTop}px` : "",
                                      marginLeft: item?.iconMarginLeft ? `${item?.iconMarginLeft}px` : '',
                                      marginRight: item?.iconMarginRight ? `${item?.iconMarginRight}px` : "",
                                      marginBottom: item?.iconMarginBottom ? `${item?.iconMarginBottom}px` : "",
                                    }}
                                  />
                                </div>
                              }
                            </div>
                        }
                      </div>
                      {item?.videoUrl &&
                        <div style={{ width: "45%", height: "120px", border: "1px solid #000", borderRadius: "5px", position: 'relative' }}>
                          <video
                            ref={el => campVideoRef.current[ind] = el}
                            poster={item?.thumbnailData}
                            style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                            src={`${item?.videoUrl}`}
                            onClick={() => handlePlayPause(item, ind)}
                          />
                          {!isPlaying[ind] &&
                            <img alt="img"
                              onClick={() => handlePlayPause(item, ind)}
                              src={PlayIcon} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "50px", height: "50px", zIndex: 999 }}
                            />
                          }
                        </div>
                      }
                      </div>
                    </div>
                  </>
              )})}
        </React.Fragment>
      </>
    </div>
  );
};

export default Video;
