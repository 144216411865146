import React from "react";
import tinycolor from "tinycolor2"

const Header = ({ data }) => {
  const values = data;


  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item, type, isWidth=true) => {
    const marginTop = item?.marginTop || 0;
    const marginBottom = item?.marginBottom || 0;
    const marginLeft = item?.marginLeft || 0;
    const marginRight = item?.marginRight || 0;

    let baseStyles = {
      // position: item?.align ? "absolute" : "relative",
      position: "relative",
      zIndex: 0,
      height: isFitToScreen ? "600px" : (backgroundHeight && backgroundHeight !== "px") ? backgroundHeight : "300px",
      width: isFitToScreen ? "336px" : (backgroundWidth && backgroundWidth !== "px") ? backgroundWidth : "150px",
      backgroundColor: (type != "Button") && backgroundColor,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`
    };
    
    if(isWidth){
      baseStyles = {
        // position: item?.align ? "absolute" : "relative",
        position: "relative",
        zIndex: 0,
        height: isFitToScreen ? "600px" : (backgroundHeight && backgroundHeight !== "px") ? backgroundHeight : "300px",
        width: isFitToScreen ? "336px" : (backgroundWidth && backgroundWidth !== "px") ? backgroundWidth : "150px",
        backgroundColor: (type != "Button") && backgroundColor,
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`
      };
    }else{
      baseStyles = {
        // position: item?.align ? "absolute" : "relative",
        position: "relative",
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`
      };
    }
    
  
    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          transform: "translateY(0)",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      default:
        return baseStyles;
    }
  };

  const getCommonPositionStyle = (baseStyle, align, item, topMargin, rightMargin, bottomMargin, leftMargin) => {
    let baseStyles = baseStyle;
  
    switch (align) {
      case "top left": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top center": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top right": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;
  
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "middle left": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle center": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle right": {
        const marginTop = topMargin ? topMargin : 0;
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
  
        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;
  
        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "bottom left": {
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginTop = topMargin ? topMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom center": {
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginTop = topMargin ? topMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
  
        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;
  
        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom right": {
        const marginBottom = bottomMargin ? bottomMargin : 0;
        const marginTop = topMargin ? topMargin : 0;
        const marginRight = rightMargin ? rightMargin : 0;
        const marginLeft = leftMargin ? leftMargin : 0;
  
        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;
  
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      default:
        return baseStyles;
    }
  }


  return (
    <React.Fragment>
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          let headerDepth = item?.headerDepth ? item?.headerDepth : 0
          let depthInPx = (600 * headerDepth) / 100

          let alpha = item?.opacity ? item?.opacity : 1
          const rgbaColor = item?.background ? tinycolor(item?.background).setAlpha(alpha).toRgbString() : "";

          return (
            <>
              <div key={ind}
                style={{
                  ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, "Header", false),
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: item?.fontColor,
                    // opacity: item?.opacity,
                    textAlign: item?.headerTextAlign,
                    // marginBottom: "10px",
                    fontSize: item.height || "12px",
                    height: item?.headerDepth ? `${depthInPx}px` : "25px",
                    border: item?.borderColor ? `1px solid ${item?.borderColor}` : "",
                    background: rgbaColor ? rgbaColor : item?.background,
                    paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
                    paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
                    paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
                    paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
                    width: "calc(100% + 10px)",
                    display: item?.headerIcon && "flex",
                    justifyContent: item?.headerIcon && "space-between",
                    position: "relative",
                    marginLeft: "-5px"
                  }}
                >
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: item?.headerText || "Header",
                    }}
                    className="editor-inner-text"
                    style={{
                      // background: item?.background,
                      width: item?.width || "100%",
                      color: item?.fontColor,
                      fontFamily: item?.headerFont,
                      // border: `1px solid ${item?.borderColor || "#000"
                      //   }`,
                      textAlign: item?.headerTextAlign,
                      // margin: "4px 0px",
                      position: item?.xTextPosition
                        ? "absolute"
                        : "relative",
                      top: item?.xTextPosition
                        ? item.xTextPosition
                        : "10",
                      [item?.headerTextAlign || "left"]:
                        item?.yTextPosition
                          ? item.yTextPosition
                          : "0px",
                      fontSize: item?.headerTextSize || "12px",
                    }}
                  />

                  {item?.headerIcon &&
                    <div style={{
                      ...getCommonPositionStyle({ position: item?.headerIconAlign ? "absolute" : "relative" }, item?.headerIconAlign, item, item?.iconMarginTop, item?.iconMarginRight, item?.iconMarginBottom, item?.iconMarginLeft),
                      zIndex: 999, display: "flex",
                      marginTop: item?.iconMarginTop ? `${item?.iconMarginTop}px` : "0px",
                      marginRight: item?.iconMarginRight ? `${item?.iconMarginRight}px` : "0px",
                      marginBottom: item?.iconMarginBottom ? `${item?.iconMarginBottom}px` : "0px",
                      marginLeft: item?.iconMarginLeft ? `${item?.iconMarginLeft}px` : "0px",
                      paddingTop: item?.iconPaddingTop ? `${item?.iconPaddingTop}px` : "0px",
                      paddingRight: item?.iconPaddingRight ? `${item?.iconPaddingRight}px` : "0px",
                      paddingBottom: item?.iconPaddingBottom ? `${item?.iconPaddingBottom}px` : "0px",
                      paddingLeft: item?.iconPaddingLeft ? `${item?.iconPaddingLeft}px` : "0px",
                    }}>
                      <img
                        src={item?.headerIcon}
                        style={{
                          // ...getPaddingStyle(item), ...getImageStyles(item?.align, false, item?.iconHeight, item?.iconHeight, "", item, type, false), zIndex: 999,
                          width: item?.iconWidth ? `${item?.iconWidth}px` : "50px", height: item?.iconHeight ? `${item?.iconHeight}px` : "20px",
                        }} />
                    </div>
                  }

                </div>
              </div>
            </>
          )
        })}
    </React.Fragment>
  );
};

export default Header;
