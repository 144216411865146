import React from "react";

const Navigation = ({data, previewData, handleSelectNextPrevPage}) => {
    const { values } = data;

    const getNavigationWrapperStyles = (item, alignmentType, isAlignByScreen) => {
        // const alignKey = isAlignByScreen ? item?.alignByScreen : item?.align;

        const baseStyles = {
            // position: "absolute",
            position: isAlignByScreen ? "absolute" : "relative",
            zIndex: 999,
            width: "100%",
        };

        let marginTop = item?.marginTop ? item?.marginTop : 0;
        let marginBottom = item?.marginBottom ? item?.marginBottom : 0;
        let marginLeft = item?.marginLeft ? item?.marginLeft : 0;
        let marginRight = item?.marginRight ? item?.marginRight : 0;

        let topMargin = marginTop - marginBottom;
        let leftMargin = marginLeft - marginRight;
        let bottomMargin = marginBottom - marginTop;

        let paddings = {
            paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
            paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
            paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
            paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
        };

        if (alignmentType?.includes("middle")) {
            return {
                ...baseStyles,
                ...paddings,
                top: item?.marginBottom ? `calc(50% - ${item?.marginBottom}px)` : "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
                marginLeft: leftMargin,
            };
        } else if (alignmentType?.includes("top")) {
            return {
                ...baseStyles,
                ...paddings,
                top: item?.marginBottom ? `-${item?.marginBottom}px` : 0,
                left: 0,
                marginTop: item?.marginTop ? `${item?.marginTop}px` : "0px",
                marginLeft: leftMargin,
            };
        } else if (alignmentType?.includes("bottom")) {
            return {
                ...baseStyles,
                ...paddings,
                bottom: item?.marginTop ? `-${item?.marginTop}px` : 0,
                left: 0,
                marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
                marginLeft: leftMargin,
            };
        } else {
            return {
                ...baseStyles,
                ...paddings,
                bottom: item?.marginTop ? `-${item?.marginTop}px` : 0,
                left: 0,
                marginBottom: item?.marginBottom ? `${item?.marginBottom}px` : "0px",
                marginLeft: leftMargin,
            };
        }
    };

    const getNavigationItemStyle = (item) => {
        let textAlign = "left";

        if (item?.align?.includes("left")) {
            textAlign = "left";
        } else if (item?.align?.includes("center")) {
            textAlign = "center";
        } else if (item?.align?.includes("right")) {
            textAlign = "right";
        } else {
            if (item?.direction === "left") {
                textAlign = "left";
            } else {
                textAlign = "right";
            }
        }
        return { textAlign: textAlign };
    };

    return (
        <>
            {values &&
                values.length > 0 &&
                values?.map((item, ind) => {
                    const alignByScreen = item?.alignByScreen ? item?.alignByScreen : item?.align;
                    const isAlignByScreen = item?.alignByScreen ? true : false;
                    return (
                        <>
                            {item?.direction === "both" ? (
                                <div style={getNavigationWrapperStyles(item, alignByScreen, isAlignByScreen)}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {item?.imageLeft ? (
                                                <div>
                                                    <img
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "prev",
                                                                previewData[previewData?.currentPage]
                                                            )
                                                        }
                                                        src={item?.imageLeft}
                                                        alt="Left"
                                                        style={{
                                                            height: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "20px",
                                                            width: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "prev",
                                                                previewData[previewData?.currentPage]
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "15px",
                                                            fontWeight: 600,
                                                            color: item?.fontColor,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item?.leftNavigationText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            {item?.imageRight ? (
                                                <div>
                                                    <img
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "next",
                                                                previewData[previewData?.currentPage]
                                                            )
                                                        }
                                                        src={item?.imageRight}
                                                        alt="Right"
                                                        style={{
                                                            height: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "20px",
                                                            width: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "next",
                                                                previewData[previewData?.currentPage]
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "15px",
                                                            fontWeight: 600,
                                                            color: item?.fontColor,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item?.rightNavigationText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={getNavigationWrapperStyles(item, alignByScreen, isAlignByScreen)}>
                                    {item?.direction === "left" && (
                                        <div style={getNavigationItemStyle(item)}>
                                            {item?.imageLeft ? (
                                                <div>
                                                    <img
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "prev",
                                                                previewData[previewData.currentPage]
                                                            )
                                                        }
                                                        src={item?.imageLeft}
                                                        alt="Left"
                                                        style={{
                                                            height: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "20px",
                                                            width: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "prev",
                                                                previewData[previewData.currentPage]
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: item?.leftNavigationSize
                                                                ? `${item?.leftNavigationSize}px`
                                                                : "15px",
                                                            fontWeight: 600,
                                                            color: item?.fontColor,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item?.leftNavigationText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {item?.direction === "right" && (
                                        <div style={getNavigationItemStyle(item)}>
                                            {item?.imageRight ? (
                                                <div>
                                                    <img
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "next",
                                                                previewData[previewData.currentPage]
                                                            )
                                                        }
                                                        src={item?.imageRight}
                                                        alt="Right"
                                                        style={{
                                                            height: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "20px",
                                                            width: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p
                                                        onClick={() =>
                                                            handleSelectNextPrevPage(
                                                                "next",
                                                                previewData[previewData.currentPage]
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: item?.rightNavigationSize
                                                                ? `${item?.rightNavigationSize}px`
                                                                : "15px",
                                                            fontWeight: 600,
                                                            color: item?.fontColor,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item?.rightNavigationText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                })}
        </>
    );
};

export default Navigation;
