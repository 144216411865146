
  export const getMammoFacLocationAction = (data) => {
    return {
      type: 'GET_MAM_FAC_LOC_RESP',
      payload: data,
    };
  };
  
  export const setMammoFacLocation = (data) => {
    return {
        type: 'SET_FAC_LOC_RESP',
        payload: data,
    };
  };
  

  
 
  
  
  