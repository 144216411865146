
import React, { useEffect, useState } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse } from "../../redux/store/fit/fitSelector";
import {
  addFitResp,
  getFitResponseAction,
} from "../../redux/store/fit/fitAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import { error } from "../../redux/store/loader/loaderSelector";
import { decodeToken } from "../../utils";

const HPVREMINDER = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const hpvResponse = useSelector(selectFitResponse);
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));
    }
  }, []);

  
  useEffect(() => {
    if (hpvResponse) {
      dispatch(setLoader(false));
      if (hpvResponse.responseDe !== "Y") {
        handleSendHPVKit("Y");
      }
    }
  }, [hpvResponse]);

  const handleSendHPVKit = (resDe) => {
    dispatch(setLoader(true));
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
    };
    dispatch(addFitResp(data));
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img alt="logo" className=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-hpvdocfit-card2 bg-fit-card-one p-0 container-340 ">
          <div className="mt-auto mb-5">
            <div className="card-thanks text-white card-help-body mb-5 p-1 py-3">
              <p className="mb-0 text-center lbl_hpv_medium">
              Please complete the HPV kit we previously sent. This screens for cervical cancer, and is important to keep you healthy.
              </p>
              <br/>
              <p className="text-center mb-0 lbl_hpv_medium">
              It takes just a few minutes and <span style={{textDecoration: "underline"}}>can save your life.</span>
              </p>
            </div>
            <div style={{ borderWidth:1, backgroundColor: "#fff", margin:'auto', width:"75%", borderRadius:12 }} >
            <div className="d-flex justify-content-center mt-5">
              <div >
                <p className="text-center mb-0  p-1 fit_question fit_question_text">Questions? Please contact your 
                  primary care provider.</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HPVREMINDER;

