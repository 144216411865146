// import Good from '../svg/Good'
// import GoodClicked from '../svg/Good_clicked'
// import Bad from '../svg/Bad'
// import BadClicked from '../svg/Bad_clicked'
// import Ns from '../svg/Ns'
// import NsClicked from '../svg/NS_clicked'

import Good from '../assets/Good.svg'
import GoodClicked from '../assets/Good.svg'
import Ns from '../assets/NS.svg'
import NsClicked from '../assets/NS.svg'
import Bad from '../assets/Bad.svg'
import BadClicked from '../assets/Bad.svg'

export const feedbacks = [
    {
        image: Good,
        imageClicked: GoodClicked,
        title: "Good",
        desc: 'Great, thank you! Please contact your provider if you have any concerns or questions',
        code:"good"
    },
    {
        image: Ns,
        imageClicked: NsClicked,
        title: "Not Sure",
        desc: "OK, let us know the main reason(s) why you're having trouble - select all that apply:",
        code:"notsure"
    },
    {
        image: Bad,
        imageClicked: BadClicked,
        title: "Bad",
        desc: "OK, let us know the main reason(s) why you're having trouble - select all that apply:",
        code:"bad"
    }
]
