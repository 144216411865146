import React, { useEffect } from "react";
import "../TemplateComponents/helpers/style.css";

const CheckList = ({ data, checkboxState, setCheckboxState, handleSave }) => {
  const { id, values } = data; 

  useEffect(() => {
    const initialState = values?.reduce((acc, item) => {
      acc[id] = acc[id] || {};
      item?.checkboxActions?.forEach(action => {
        acc[id][action?.id] = {
          question: action?.actionText,
          result: action?.isChecked ? "checked" : "unchecked",
        };
      });
      return acc;
    }, {});
    setCheckboxState(prevState => ({ ...prevState, ...initialState }));
  }, [setCheckboxState, values, id]);

  const handleCheckboxChange = (parentId, questionId, question, e) => {
    const newState = {
      ...checkboxState,
      [parentId]: {
        ...checkboxState[parentId],
        [questionId]: {
          question,
          result: e.target?.checked ? "checked" : "unchecked",
        },
      },
    };

    let payload = {
      parentId: parentId,
      id: String(questionId),
      question: question ? question : "",
      result: e.target?.checked ? "checked" : "unchecked",
    }

    setCheckboxState(newState);
    handleSave(payload, data?.type);  
  };

  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values.map((item, ind) => {
          return(
          <div key={ind} className="mb-3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: item?.textColor,
                  fontSize: "1.3rem",
                }}
              >
                {item?.headerText}
              </p>
              <p style={{ fontSize: "0.8rem" }}>Status</p>
            </div>
            {item?.bodyText && <p>{item?.bodyText}</p>}
            <div className="mt-2">
              {item?.checkboxActions &&
                item?.checkboxActions.length > 0 &&
                item?.checkboxActions.map((action, actionInd) => {
                  return(
                  <div
                    key={actionInd}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // alignItems: "flex-start",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <label style={{color: item?.labelColor, width: "70%",}}
                    >
                      {action.actionText}
                    </label>
                    <input
                      checked={ checkboxState[id]?.[action.id]?.result === "checked" }
                      onChange={(e) => handleCheckboxChange(id, action?.id, action?.actionText, e) } 
                      style={{
                        appearance: "none",
                        height: "30px",
                        width: "30px",
                        borderRadius: "5px",
                        border: `1px solid ${item?.labelColor ? item?.labelColor : "#0d1c3d"}`,
                        backgroundColor: (checkboxState[id]?.[action.id]?.result === "checked"  ? item?.labelColor : "#fff") || (action?.isChecked == true ? "#0d1c3d" : item?.labelColor )  }}
                      //   backgroundColor:
                      //     checkboxState[id]?.[action.id]?.result === "checked"
                      //       ? item?.labelColor
                      //       : "#fff",
                      // }}
                      type={"checkbox"}
                    />
                  </div>
                )})}
            </div>
          </div>
        )
      } 
        )}
    </div>
  );
};

export default CheckList;
