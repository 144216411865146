// MamoSummary.js
import React from "react";
import { decodeToken } from "../../utils";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"; // Import Link from react-router-dom
const MamoSummary = () => {
  const { company, campaignId } = useParams();
  let token = localStorage.getItem("token");
  const data = decodeToken(token);
  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-mamo-card-2  p-0 container-340 ">
          <div className="mt-auto mb-2">
            <div className="card-thanks text-white card-help-body mb-1 p-2">
              <p className="mb-0 lbl_small">
              Things to know before you schedule:
              </p>
              <ul className="m-0 mt-2 ps-3">
                <li className="mb-0 ps-2 lbl_small">
                Screening mammograms are the best way to detect breast cancer in women without symptoms.
               
                </li>
              <li className="mb-0 ps-2 lbl_small">
                  If you have any of the following symptoms, please contact your doctor before scheduling your mammogram to determine the best screening for you:
                </li>
              </ul>
              <ul className="ms-3 ps-4 mb-0">
                <li className="mb-0 ps-2 lbl_small">Breast lump(s) </li>
                <li className="mb-0 ps-2 lbl_small">Change in skin on breast(s)</li>
                <li className="mb-0 ps-2 lbl_small">Nipple discharge</li>
                <li className="mb-0 ps-2 lbl_small">
                Breast surgery in the past 6 months
                </li>
              </ul>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between mr-2 navigation_img_wrapper">
                <Link to={`/${company}/${campaignId}/mammo/?token=${token}`}>
                  <img
                    className="ms-2 navigation_img"
                    src="/Back_dark.svg"
                    height="30"
                    width="100"
                    alt="Back Dark"
                  />
                </Link>

                {data.MessageType == "4" || data.MessageType == "7" ? (
                  <Link to={`/${company}/${campaignId}/mammo/message`}>
                    <img
                      className="me-2 navigation_img"
                      src="/Next_dark.svg"
                      height="30"
                      width="100"
                      alt="Next Dark"
                    />
                  </Link>
                ) : (
                  <Link to={`/${company}/${campaignId}/mammo/location`}>
                    <img
                      className="me-2 navigation_img"
                      src="/Next_dark.svg"
                      height="30"
                      width="100"
                      alt="Next Dark"
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MamoSummary;
