import React from "react";

const DuelGraphic = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div key={ind} className="d-flex justify-content-between align-items-center mb-3">
            <div style={{ width: "30%" }}>
              <img
                src={item?.imageFirst}
                alt=""
                style={{ width: "90%", height: "auto" }}
              />
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <i
                className="fa fa-plus"
                aria-hidden="true"
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#123F70",
                }}
              ></i>
            </div>
            <div style={{ width: "30%" }}>
              <img
                src={item?.imageSecond}
                alt=""
                style={{ width: "90%", height: "auto" }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: item?.prescriptionText || "",
                }}
                className="editor-inner-text"
                style={{
                  color: item?.prescriptionTextColor,
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default DuelGraphic;
