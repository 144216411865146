import React, { useEffect, useState } from "react";
import './App.css';
import Home from './pages/Home';
import Index from './pages/pharmacycompages';
import ApptCard from './pages/pharmacycompages/ApptCard';
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AppointmentSummary from './pages/AppointmentSummary';
import ApptSummary from './pages/pharmacycompages/ApptSummary';
import ApptCall from './pages/pharmacycompages/ApptCall';
import 'react-datepicker/dist/react-datepicker.css';
import { Suspense } from 'react';
import Loader from './pages/components/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { error, loading, tokenError } from './redux/store/loader/loaderSelector';
import ErrorLoader from './pages/components/ErrorLoader';
import TokenErrorLoader from './pages/components/tokenError';
import OtpEnrollment from './pages/pharmacycompages/OtpEnrollment';
import OutOfService from './pages/pharmacycompages/OutOfService';
import StayEnrolled from './pages/pharmacycompages/StayEnrolled';
import Mtm from './mtm/mtm';
import MtmSummary from './mtm/mtmSummary';
import FITKIT from './pages/fit/fitkit';
import FITKITSummary from './pages/fit/fitkitSummary';
import Mamo from './pages/mammo/Mamo';
import MamoSummary from './pages/mammo/MamoSummary';
import MamoLocation from './pages/mammo/MamoLocation';
import MamoMessage from './pages/mammo/MamoMessage';
import ColoSummary from './pages/colo/ColoSummary';
import Container from './pages/components/Container';
import ColoPrepDetails from './pages/colo/components/Details';
import ValidateDob from './pages/colo/components/ValidateDob';
import FITKIT2 from './pages/fit/fitkit2';
import FitPrimer from './pages/fit/fitPrimer';
import FitAfricanAmerican from './pages/fit/fitAfricanAmerican';
import FitHispanic from "./pages/fit/fitHispanic";
import FitReminderSixMonth from './pages/fit/fitremindersixmonth';
import FitReminderOne from './pages/fit/fitreminderone';
import FitDynamicThanks from './pages/fit/fitDynamicThanks';
import HPVKIT from './pages/hpv/hpvkit';
import HPVKITSummary from './pages/hpv/hpvkitSummary';
import HPVREMINDER from './pages/hpv/hpvreminder';
import HPVPrimer from "./pages/fit/hpvPrimer";
import Diabetes from './pages/diabetes/diabetes';
import { decodeToken } from './utils';
import {saveCampaignOpenStatus} from './redux/store/common/comAction'
import FitPi from "./pages/fit/fitPi";

function App() {
  const dispatch = useDispatch();
  const loader = useSelector(loading);
  const isError = useSelector(error);
  const tokenMessage = useSelector(tokenError)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

useEffect(() => {
  var user = decodeToken(token);
  if(user != undefined && user !== null){
    const jsonObject = {
      userId: user.uid,
      schedulerId: user.scheduleCode
    };
    if(user && user.campCode != "M4AI2K" && user.campCode != "QUO7UE9")
      dispatch(saveCampaignOpenStatus(jsonObject))
  }
}, [token])

  return (
    <Suspense fallback={<Loader />}>
      {loader && <Loader />}
      {isError && <ErrorLoader />}
      {tokenMessage && <TokenErrorLoader />}
      { }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:company/:campaignId" element={<Index />} />
        <Route path="/summary" element={<AppointmentSummary />} />
        <Route path="/:company/:campaignId/campaign" element={<Container />} />
        <Route path="/:company/:campaignId/appointment" element={<ApptCard />} />
        <Route path="/:company/:campaignId/appointment/Summary" element={<ApptSummary />} />
        <Route path="/:company/:campaignId/appointment/Call" element={<ApptCall/>} />
        <Route path="/:company/:campaignId/otpEnrollment" element={<OtpEnrollment />} />
        <Route path="/:company/:campaignId/canceledService" element={<OutOfService />} />
        <Route path="/:company/:campaignId/stayEnrolled" element={<StayEnrolled />} />

        <Route path="/:company/:campaignId/fitPrimer" element={<FitPrimer />} />
        {/* <Route path="/:company/:campaignId/fitreminder" element={<FITKIT2 />} /> */}

        <Route path="/:company/:campaignId/fitreminder" element={<FitHispanic />} />

        <Route path="/:company/:campaignId/fit" element={<FITKIT />} />
        <Route path="/:company/:campaignId/fitremindervtwo" element={<FitReminderSixMonth/>}/>
        {/* <Route path="/:company/:campaignId/fitremindervone" element={<FitReminderOne/>}/> */}
        <Route path="/:company/:campaignId/fitremindervone" element={<FitPi/>}/>

        <Route path="/:company/:campaignId/fit/summary" element={<FITKITSummary />} />
        <Route path="/:company/:campaignId/fit/thank" element={<FitDynamicThanks />} />
        <Route path="/:company/:campaignId/fitafam" element={<FitAfricanAmerican />} />
        {/* <Route path="/:company/:campaignId/fithispanic" element={<FitHispanic />} /> */}
        {/* <Route path="/:company/:campaignId/fitPi" element={<FitPi/>} /> */}
        

        <Route path="/:company/mtm" element={<Mtm />} />
        <Route path="/:company/mtm/summary" element={<MtmSummary />} />
      
        <Route path="/:company/:campaignId/mammo" element={<Mamo/>} />
        <Route path="/:company/:campaignId/mammo/summary" element={<MamoSummary/>} />
        <Route path="/:company/:campaignId/mammo/location" element={<MamoLocation/>} />
        <Route path="/:company/:campaignId/mammo/message" element={<MamoMessage/>} />
        
        <Route path="/:company/colo/summary" element={<ColoSummary />} />
        <Route path="/:company/colo/validatedob" element={<ValidateDob />} />
        <Route path="/:company/colo/details/:prepDay" element={<ColoPrepDetails/>}/>

        <Route path="/:company/:campaignId/hpvfit" element={<HPVKIT />} />
        <Route path="/:company/:campaignId/hpvfit/summary" element={<HPVKITSummary />} />
        <Route path="/:company/:campaignId/hpvreminder" element={<HPVREMINDER />} />
        <Route path="/:company/:campaignId/hpvPrimer" element={<HPVPrimer />} />
      
        <Route path="/:company/:campaignId/diabetes" element={<Diabetes/>} />
      </Routes>
    </Suspense>
  );
}

export default App;
