import React from 'react'

const DoubleImage = ({ data }) => {
    return (
        <div class="d-flex justify-content-center align-items-center">
            <div class="patient-avatar text-end">
                <div><img className='w-75' src={"../../../../" + data.Img1} alt="" /></div>
                {
                  data && data.Img2Text && <div class='ps20px'>&nbsp;</div>
                }
            </div>
            <div class="avatar-plus-icon">+</div>
            <div class="patient-avatar">
                <div><img className='w-75' src={"../../../../" + data.Img2} alt="" /></div>
                {
                  data && data.Img2Text && <div class='ps20px'>{data.Img2Text}</div>
                }
            </div>    
        </div>
        // <div className="double-image-main" style={{ display: 'flex', alignItems: 'center' }}>
        //     <div className='double-image'>
        //         <img src={data.leftImage} alt='' />
        //     </div>
        //     <svg
        //         className="plus-icon"
        //         xmlns="http://www.w3.org/2000/svg"
        //         viewBox="0 0 24 24"
        //         fill="none"
        //         stroke="currentColor"
        //         strokeWidth="2"
        //         strokeLinecap="round"
        //         strokeLinejoin="round"
        //         style={{ margin: '0 10px', width: "40px", height: "40px" }}
        //     >
        //         <line x1="12" y1="5" x2="12" y2="19"></line>
        //         <line x1="5" y1="12" x2="19" y2="12"></line>
        //     </svg>
        //     <div className='double-image'>
        //         <img src={data.rightImage} alt='' />

        //     </div>
        // </div>
    )
}

export default DoubleImage