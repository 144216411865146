import React, { useEffect, useState } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse } from "../../redux/store/fit/fitSelector";
import {
  addFitResp,
  getFitResponseAction,
} from "../../redux/store/fit/fitAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import { error } from "../../redux/store/loader/loaderSelector";
import { decodeToken } from "../../utils";

const HPVKIT = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fitResponse = useSelector(selectFitResponse);
  const errorMessage = useSelector(error);
  const queryParams = new URLSearchParams(location.search);
  const { company, campaignId } = useParams();
  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));
    }
  }, []);

  useEffect(() => {
    if (fitResponse) {
      dispatch(setLoader(false));
      if (fitResponse.responseDe === "Y") {
        navigate(`/${company}/${campaignId}/hpvfit/summary`);
      } else if (
        fitResponse?.responseDe === null &&
        fitResponse.responseDe !== "N"
      ) {
        handleSendHPVKit("N");
      }
    }
  }, [fitResponse]);

  const handleSendHPVKit = (resDe) => {
    dispatch(setLoader(true));
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
    };
    dispatch(addFitResp(data));
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-hpvfit-card2 bg-fit-card-one p-0 container-340 ">
          <div className="mt-auto mb-4">
            <div className="card-thanks text-white card-help-body mb-1 p-1">
              <p className="mb-0 text-center lbl_hpv_medium">
              Please complete the HPV kit we previously sent. This screens for cervical cancer, and is important to keep you healthy.
              </p>
              <img
                src="/HPVSWAB.svg"
                alt="HPVKIT Logo"
                style={{ height: "60px", width: "100%" }}
              />

              <p className="text-center mb-0 lbl_hpv_medium">
              If you require a new kit, let us know by clicking the button.
              </p>
              <p className="text-center mb-0 lbl_hpv_medium mt-3">
              Questions? Please contact your primary care provider.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div
                className="btn_global"
                onClick={() => handleSendHPVKit("Y")}
              >
                <span className="mb-0">
                  Send my HPV kit
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HPVKIT;
