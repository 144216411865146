import React from "react";
import KpLogo from '../../assets/images/KP_logo.svg'

const OutOfService = () => {

    return(
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
            <div className='card-header bg-inky text-white text-center'>
          <img className=" w-15" src="../../Logo.svg" />
        </div>
                <div className='card-body card-body-h-vh p-0 container-340 '>
                    <div className="mt-5">
                        <div className='bg-white text-inky card-help-body mb-3'>
                            <p className="mt-5 mb-0 text-center normal_text">Okay, you have been 
                                opted out of future 
                                medication reviews 
                                provided by the 
                                Medication Therapy 
                                Management Program. 
                            </p>
                            <p className="mt-2 mb-0 text-center normal_text">
                                If you change your mind 
                                at any time this year and 
                                would like to re-enroll, 
                                calls us at 
                                <span className="d-block w-100 normal_text">1-866-380-2588.</span>
                            </p>
                        </div>
                    </div>
                    <div className="mt-auto mb-3 text-center out_service_logowrapper">
                        <img src={KpLogo} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OutOfService;