import React, { useEffect, useState } from 'react'
import './index.css'
import { feedbacks } from './helper/helper'
import { useDispatch, useSelector } from 'react-redux';
import { addMtmResp, setMtmResponse, getMtmResponseAction, getMtmSurveyOptionAction, getPageLocalizedDataAction } from '../redux/store/mtm/mtmAction';
import medSubOptions from './helper/medSubOptions.json';
import { useLocation, useNavigate } from 'react-router-dom';
import { decodeToken } from '../utils';
import { setError, setLoader, setTokenError } from '../redux/store/loader/loaderAction';
import { selectMtmResponse, selectMedSurveyOptions, selectPageLocalizedData } from '../redux/store/mtm/mtmSelector';

const Mtm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const [questions, setQuestions] = useState()
    const [mtmOption, setMtmOption] = useState()
    const [opaClass, setOpaClass] = useState()
    const queryParams = new URLSearchParams(location.search);
    const token = new URLSearchParams(location.search).get("token");
    const [status, setStatus] = useState({})
    const [subQuestions, setSubQuestions] = useState([]);
    const [subQuestionsForAnimation, setSubQuestionsForAnimation] = useState([]);
    const [questionsForAnimation, setQuestionsForAnimation] = useState({});
    let [answer, setAnswer] = useState({})
    const mtmResponse = useSelector(selectMtmResponse);
    const surveyOptions = useSelector(selectMedSurveyOptions);
    const localizedData = useSelector(selectPageLocalizedData);
    const [statusData, setStatusData] = useState();
    // const [isClicked, setIsClicked] = useState(false);
    // const [isClickedOther, setIsClickedOther] = useState(false);
    const [isExpanded, setIsExpanded] = useState({});


    const stylesEven = {
        backgroundColor: 'red'
    };
    const stylesOdd = {
        backgroundColor: 'green'
    };

    // const handleClick = () => {
    //     setTimeout(() => {
    //         setIsClicked(true);
    //     }, 300);
    // };

    // const handleClickOther = () => {
    //     setTimeout(() => {
    //         setIsClickedOther(!isClickedOther);
    //     }, 300);
    // };

    useEffect(() => {
        if (mtmResponse) {
            if ((mtmResponse && mtmResponse.resp && mtmResponse.resp.length > 0)) {
                navigate('Summary');
            }
            else {
                const data = decodeToken(token);
                const filteredData = Object.keys(data)
                    .filter(key => key.startsWith("medList"))
                    .reduce((obj, key) => {
                        obj[key] = data[key];
                        return obj;
                    }, {});

                let qstList = []
                Object.values(filteredData).map(medList => {
                    medList.split('^').forEach(med => {
                        qstList.push(med);
                    });
                })
                setQuestions(qstList)
                setMtmOption(data["mtm"]);

                // if (data["mtm"] == 1) {
                //     setSubQuestions(medSubOptions["Mtm"])
                // }
                // else {
                //     setSubQuestions(medSubOptions["Non-Mtm"])
                // }
            }
        }
    }, [mtmResponse])

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token)
            const data = decodeToken(token);
            if (data) {
                //check if user already submitted response
                dispatch(getMtmResponseAction({ id: data['uid'], campCode: data['campCode'] }))
                dispatch(getMtmSurveyOptionAction());
                dispatch(getPageLocalizedDataAction());
                
            }
        }
    }, [token])

   
    useEffect(() => {
        if(surveyOptions && surveyOptions.medSurveyOptions){
            setSubQuestionsForAnimation(surveyOptions.medSurveyOptions["notsure"].SubOptions);
            setQuestionsForAnimation(surveyOptions.medSurveyOptions["notsure"].Options);
        }
    },[surveyOptions])


    const handleAnswer = (question, event, subquestion, index, baseSubOptionDe, subOptionType) => {
        if (subOptionType == "OTHER") {
            let list = isExpanded;
            list[index] = event.target.checked
            setIsExpanded({ ...list });
        }
        if (event.target.checked) {
            const answerUpdate = {
                ...answer,
                [question]: {
                    status: answer[question].status,
                    mtm: answer[question].mtm,
                    answer: {
                        ...answer[question].answer,
                        [baseSubOptionDe]: event.target.checked
                    }
                }
            }
            setAnswer(answerUpdate)
        } else {
            const updatedAnswer = { ...answer };
            if (updatedAnswer[question]?.answer[baseSubOptionDe]) {
                delete updatedAnswer[question]?.answer[baseSubOptionDe];
            }
            setAnswer(updatedAnswer);
        }
    }

    const handleChange = (event, question) => {
        const answerUpdate = {
            ...answer,
            [question]: {
                status: answer[question].status,
                mtm: answer[question].mtm,
                answer: {
                    ...answer[question].answer,
                    other: event
                }
            }
        }
        setAnswer(answerUpdate)
    }

    const handleSubmit = () => {
        dispatch(setLoader(true))
        dispatch(addMtmResp(answer))
    }

    const handleStatus = (question, data) => {
        const statusUpdate = {
            ...status,
            [question]: data
        }
        setStatus(statusUpdate);
        setStatusData(data.color)
        const answerUpdate = {
            ...answer,
            [question]: {
                status: data.color,
                answer: {},
                mtm: mtmOption
            }
        }
        setAnswer(answerUpdate);
        setSubQuestions(data.subOptions);
        getOpaClass(question, data.color);
    }

    const getOpaClass = (question, color) => {
        if ((status && status[question] && status[question].imageClicked && status[question].color == "bad" ||
            status && status[question] && status[question].imageClicked && status[question].color == "notsure") && color != "good") {
            setTimeout(() => {
                setOpaClass(' show')
            }, 400);
            setOpaClass('')
        }
        else {
            setOpaClass(' show')
            // setTimeout(() => {

            // }, 1);
            //setOpaClass('')
        }
    }

    const getHeight = (status, question) => {
        let divElement = document.querySelector(".qstDescription");
        let elemHeight = 0;
        let optionsHeight = 0;

        if(divElement != null){
            elemHeight = divElement.scrollHeight + 20;

            divElement = document.querySelector(".qstSubOptions");
            optionsHeight = divElement.scrollHeight;
        }

        if (status && status[question] && status[question].imageClicked && status[question].color == "good") {
            return "55px";
        }
        else if (status && status[question] && status[question].imageClicked && status[question].color == "notsure") {
            if (answer[question].answer["Other"]) {
                return optionsHeight + elemHeight + 90;
                //return "auto";
            }
            else {
                return optionsHeight + elemHeight;
                //return "auto";
            }
        }
        else if (status && status[question] && status[question].imageClicked && status[question].color == "bad") {
            if (answer[question].answer["Other"]) {
                return optionsHeight + elemHeight + 90;
                //return "auto";
            }
            else {
                return optionsHeight + elemHeight;
                //return "auto";
            }
        }
        else {
            return "0px";
        }
    }

    const getOpacity = (status, question) => {
        if (status && status[question] && status[question].imageClicked && status[question].color == "good") {
            return 1;
        }
        else if (status && status[question] && status[question].imageClicked && status[question].color == "notsure") {
            return 1;
        }
        else if (status && status[question] && status[question].imageClicked && status[question].color == "bad") {
            return 1;
        }
        else {
            return 0;
        }
    }

    const getLocalizedDataByKey = (key) => {
        if(localizedData && localizedData.localizedData){
            return localizedData.localizedData.filter(x => x.Key == key)[0].Value;
        }
        return "";
    }

    return (
        <div className="container-fluid g-0">
            <div className="card card-custom">
                <div className='card-header bg-blue text-white text-center'>
                    <img class=" w-15" src="../Logo.svg" />
                </div>
                <div className="card-body pt-0">
                    <div className="divider-content-group">
                        <h5 className="text-left mb-2 mt-3 text-blue">{getLocalizedDataByKey("SURVEY_PAGE_HEADER")}</h5>
                        <p className="text-gray">{getLocalizedDataByKey("SURVEY_PAGE_SUB_HEADER")}</p>
                    </div>
                    
                    {questions && Object.values(questions) && Object.values(questions).length && Object.values(questions).map((question, index) => {
                        return <div className='divider-content-group'>
                            {  index == 0 && <div>
                                <div className='qstDescription'>
                                    {questionsForAnimation.Desc}
                                </div>
                                <div className='qstSubOptions'>
                                    {subQuestionsForAnimation.map((subquestion) => {
                                        return (
                                            <div className="d-flex justify-content-between">
                                                <div className='mt-1 col-11'>
                                                    <p className='text-gray'>{subquestion.SubOptionDe}</p>
                                                </div>
                                                <div className="chec-box-columns">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                            <div className="divider-content-group">
                                <label className="text-gray mt-3">{question}</label>
                                <div className="health-status mt-1">
                                    <div className="row" id="health-status" role="tablist">
                                        {surveyOptions && surveyOptions.medSurveyOptions && Object.keys(surveyOptions.medSurveyOptions) && 
                                        Object.keys(surveyOptions.medSurveyOptions).map((feedback, i) => {
                                            return (
                                                <div className="col col-md-4" onClick={() => {
                                                    handleStatus(question, {
                                                        id: i,
                                                        color: feedback,
                                                        description: surveyOptions.medSurveyOptions[feedback].Options.Desc,
                                                        title: surveyOptions.medSurveyOptions[feedback].Options.Title,
                                                        image: feedbacks.filter(x => x.code == surveyOptions.medSurveyOptions[feedback].Options.Code)[0].image,
                                                        imageClicked: feedbacks.filter(x => x.code == surveyOptions.medSurveyOptions[feedback].Options.Code)[0].imageClicked,
                                                        subOptions: surveyOptions.medSurveyOptions[feedback].SubOptions
                                                    })
                                                }}>
                                                    <p className={`position-relative pb-0 mb-3 health-box health-${status && status[question] && status[question].id === i && status[question].color} text-center active`}
                                                        data-bs-toggle="list" role="tab">
                                                        {
                                                            <img className="tab-images" src={status && status[question] && status[question].id === i
                                                                ? feedbacks.filter(x => x.code == surveyOptions.medSurveyOptions[feedback].Options.Code)[0].imageClicked : feedbacks.filter(x => x.code == surveyOptions.medSurveyOptions[feedback].Options.Code)[0].image} alt={feedback.title} />
                                                        }
                                                        <p className="m-0 titlePos">{surveyOptions.medSurveyOptions[feedback].Options.Title}</p>
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className={`otherSecCollapse1 fade ` + opaClass} style={{
                                    height: getHeight(status, question)
                                }}>
                                    <div>
                                        {
                                            status && status[question] && <div className="tab-pane ">
                                                <p className='text-gray'>{status[question].description}</p>
                                            </div>
                                        }
                                    </div>
                                    {status && status[question] && status[question].color && subQuestions.map((subquestion) => {
                                        return (
                                            <>
                                                <div className="d-flex justify-content-between">
                                                    <div className='mt-1 col-11'>
                                                        <p className='text-gray'>{subquestion.SubOptionDe}</p>
                                                    </div>
                                                    <div className="chec-box-columns">
                                                        <div className="form-check">
                                                            <input className="form-check-input" onClick={(e) => {
                                                                handleAnswer(question, e, subquestion.SubOptionDe, index, subquestion.BaseSubOptionDe, subquestion.SubOptionType)
                                                            }} type="checkbox" value=""
                                                                id="flexCheckDisabled" checked={answer[question] && answer[question].answer && answer[question].answer[subquestion.BaseSubOptionDe]} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (subquestion.SubOptionType == "OTHER") && <>
                                                    <div className={`flex flex-column mt-3 mb-3 otherSecCollapse`}
                                                        style={{ height: answer[question].answer[subquestion.BaseSubOptionDe] ? "70px" : "0px" }}>
                                                        <div className="form-group form-group-textarea">
                                                            <textarea className="form-control" rows="2" maxlength="70" onChange={(e) => handleChange(e.target.value, question)}></textarea>
                                                            <span className="charector-count">
                                                                <span className="char-count">{answer[question].answer && answer[question].answer.other ? answer[question].answer.other.length : 0}</span>
                                                                <span className="total-char">/70</span>
                                                            </span>
                                                        </div>
                                                    </div></>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div>    
                        </div>
                    })}

                    <div className="text-center mb-5 mt-4">
                        <button className="btn-submit-cureved d-flex  align-items-center mx-auto" onClick={() => handleSubmit()} disabled={Object.keys(answer).length == 0 ? true : false}>
                            <span className="btn-icon"></span>
                            <div className="btnSubmitText">{getLocalizedDataByKey("SURVEY_PAGE_SUBMIT")}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mtm