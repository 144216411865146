import React, { useEffect } from 'react'
import '../index.css'
import { useDispatch, useSelector } from 'react-redux'
import { userBookedPharmacyAppointment } from '../../redux/store/appointment/appointmentSelector'
import { getUserPharmacyAppot } from '../../redux/store/appointment/getAppointment';
import moment from 'moment'

const ApptSummary = () => {
    const dispatch = useDispatch();
    const bookedAppointment = useSelector(userBookedPharmacyAppointment);
    useEffect(() => {
        if(!bookedAppointment){
            dispatch(getUserPharmacyAppot({}));
        }
    }, [])
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inkytext-white text-center'>
                    <img class=" w-15" src="../../../Logo.svg" />
                </div>
                <div className='card-body card-body-h-vh bg-card p-0 container-340 '>
                    <div className='help-card-bottom help-card-2'>
                        <div className='bg-white text-inky card-help-body opicity-5'>
                            <p className="mb-0 normal_text">Thank you - one of our pharmacists will give you a call on:</p>
                            {bookedAppointment && bookedAppointment.date && <p className="fw-semibold  mt-3 fs-5 pb-0 mb-0">{moment(bookedAppointment.date).format('dddd Do MMMM')} <br /> at {moment(bookedAppointment.date).format('LT')}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApptSummary