import { createSelector } from 'reselect';

const mtmReducer = (state) => state.mtm

export const selectMtmResponse = createSelector(
    [mtmReducer],
    (mtm) => mtm.mtm
);

export const selectMedSurveyOptions = createSelector(
    [mtmReducer],
    (mtmOptions) => mtmOptions.mtmOptions
);

export const selectPageLocalizedData = createSelector(
    [mtmReducer],
    (mtmLocalizedData) => mtmLocalizedData.mtmLocalizedData
);