import React from "react";
import "./helpers/style.css";
const ActionButton = ({
  data,
  handleSave,
  selectedButton,
  setSelectedButton,
}) => {
  const { values } = data;


  const handleClick = (item) => {
    const stringId = String(item?.id);
    setSelectedButton({ parentId: data?.id, id: item?.id });

    handleSave(
      {
        parentId: data?.id,
        id: stringId,
        question: item?.buttonText ? item?.buttonText : "",
        result: "true",
      },
      data.type
    );
  };

  return (
    <div className="control_padding">
      {/* {values &&
        values.length > 0 &&
        values.map((item, ind) => {
          let buttonText = item.buttonText
            ? item.buttonText
            : ind === 0
            ? "Button"
            : "Button" + " " + ind;

          const isSelected =
            selectedButton?.parentId === data.id && selectedButton.id === item.id;

          return (
            <div key={ind} className="mb-3 action-button-wrapper ">
              <button
                onClick={() => handleClick(item)}
                style={{
                  border: `1px solid ${item.buttonTextColor}`,
                  color: isSelected ? item?.buttonTextColor : item?.buttonTextColor,
                  backgroundColor: (item.responseDe === true ? "#92CCF0" : "#fff") || (isSelected ? '#92CCF0' : "#fff"),
                  borderRadius: "35px",
                  width: "100%",
                  padding: "5px 10px",
                }}
              >
                {buttonText}
              </button>
            </div>
          );
        })} */}
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          let buttonText = item?.buttonText
            ? item?.buttonText
            : ind === 0
            ? "Button"
            : "Button" + " " + ind;

            const isSelected =
            selectedButton?.parentId === data?.id && selectedButton?.id === item?.id;
          return (
            <div key={ind} className="mb-3 action-button-wrapper">
              <button
               onClick={() => handleClick(item)}
                dangerouslySetInnerHTML={{ __html: buttonText }}
                style={{
                  color: isSelected ? item?.buttonTextColor : item?.buttonTextColor,
                  border: item?.borderColor
                    ? `1px solid ${item?.borderColor}`
                    : `1px solid #0D1C3D`,
                  backgroundColor: (item?.responseDe === true ? "#92CCF0" : "#fff") || (isSelected ? '#92CCF0' : "#fff"),
                  borderRadius: "35px",
                  opacity: item?.opacity,
                  width: "100%",
                  padding: "5px 10px",
                }}
              >
                {/* {buttonText} */}
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default ActionButton;
