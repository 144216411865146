import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";
import { setMtmResponse, getMtmResponseAction, setMtmSurveyOptions, getPageLocalizedDataAction, setPageLocalizedData } from "./mtmAction";
import { decodeToken } from '../../../utils';

export function* addMtmResponse(data) {
  const token = localStorage.getItem('token')
  if(data === undefined)
    return;
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    yield axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/mtm/Create`, data.payload, config);
    yield put(setLoader(false))
    //yield put(setMtmResponse(true))
    const data1 = decodeToken(token);
    if (data1) {
        yield put(getMtmResponseAction({ id: data1['uid'], campCode: data1['campCode'] }))
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
  }
}

export function* getMtmResponse(user) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    const mtmResponse = yield axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/mtm/getResponse`, user.payload, config);
    yield put(setMtmResponse(mtmResponse.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* getSurveyOptions(user) {
  
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    const mtmSurveyOptions = yield axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/mtm/getSurveyOptions`, {}, config);
    yield put(setMtmSurveyOptions(mtmSurveyOptions.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* getPageLocalizedData(user) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    const pageLocalizedData = yield axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/mtm/getPageLocalizedData`, {}, config);
    yield put(setPageLocalizedData(pageLocalizedData.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* onAddMtmResponse() {
  yield takeLatest("ADD_MTM_RESP", addMtmResponse);
}

export function* onGetMtmResponse() {
  yield takeLatest("GET_MTM_RESP", getMtmResponse);
}

export function* onGetMtmSurveyOptions() {
  yield takeLatest("GET_MTM_SURVEY_OPTIONS", getSurveyOptions);
}

export function* onGetPageLocalizedData() {
  yield takeLatest("GET_PAGE_LOCALIZED_DATA", getPageLocalizedData);
}

export function* mtmSagas() {
  yield all([call(onAddMtmResponse), call(onGetMtmResponse), call(onGetMtmSurveyOptions), call(onGetPageLocalizedData)]);
}
