import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../../redux/store/loader/loaderAction";
import { decodeToken } from "../../../utils";

import Header from './Header'
import SubHeader from './SubHeader'
import ActionHeader from './ActionHeader'
import ButtonList from './ButtonList'
import axios from 'axios'
import './main.css'
import Image from './Image'
import BulletPoint from './BulletPoint'
import Description from './Description'
import CheckList from './CheckList'
import Cancel from './Cancel'
import Success from './Success'
import SingleImage from './SingleImage'
import DoubleImage from './DoubleImage'
import Maps from './Maps'
import ResourceLink from './ResourceLink';
import Footer from './Footer';

const Main = () => {
  const [data, setData] = useState([])
  const [locationDetails, setLocationDetails] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const [msgId, setMsgId] = useState(0);
  const token = queryParams.get("token");
  const userContext = decodeToken(token);
  const { prepDay } = useParams();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(setError(""));
    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    }
    else {
      getData()
      getUserResponse();
    }
  }, [])

  const getData = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      };
      const data = await axios.get(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/component/getComponentDetails/?id=${prepDay}`, config)
      const response = data.data.component
      setData(response)
      setLocationDetails(data.data.facilityDetails);
    } catch (err) {
      console.error(err);
    }
  }

  const getUserResponse = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      };
      const data = await axios.get(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/component/getUserResponse?id=${prepDay}`, config)
      const response = data.data.userResponse
      setUserOptions(response)
    } catch (err) {
      console.error(err);
    }
  }

  const saveUserResponse = async (qstId, respCode) => {
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    };
    try {
      dispatch(setLoader(true));
      let userResponses = await axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/component/saveUserResponse`, { qstId: qstId, respCode: respCode }, config);
      setUserOptions([...userResponses.data.userResponse]);
      dispatch(setLoader(false));
    } catch (err) {
      setError(err.response.data.error)
      setOpen(true)
      console.log(err);
      dispatch(setLoader(false));
    }
  }

  const deleteUserResponse = async (qstId, respCode) => {
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    };
    try {
      dispatch(setLoader(true));
      let userResponses = await axios.post(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/component/deleteUserResponse`, { qstId: qstId, respCode: respCode }, config);
      if(!userResponses.data.userResponse || (userResponses.data.userResponse && userResponses.data.userResponse.length == 0)){
        let noOptions = [];
        setUserOptions(noOptions);  
      }
      else{
        setUserOptions([...userResponses.data.userResponse]);
      }
      dispatch(setLoader(false));
    } catch (err) {
      setError(err.response.data.error)
      setOpen(true)
      console.log(err);
      dispatch(setLoader(false));
    }
  }

  return (
    <div className='container-fluid g-0'>
      <div className='card card-custom card-help'>
        <div className='card-header bg-blue text-white text-center'>
          <img class=" w-15" src="../../../Logo.svg" />
        </div>
        <div className="container-fluid">
          <div className="stpper-details-page mt-4 pb-5">
            {data?.length > 0 && data.map((data, id) => {
              let dataValue;
              switch (data.name) {
                case "Header":
                  dataValue = (<Header header={JSON.parse(data.value)} />)
                  break;
                case "Sub_Header":
                  dataValue = (<SubHeader subheader={JSON.parse(data.value)} userContext={userContext} locationDetails={locationDetails} />)
                  break;
                case "Action_Header":
                  dataValue = (<ActionHeader action={JSON.parse(data.value)} />)
                  break;
                case "image":
                  dataValue = (<Image data={JSON.parse(data.value)} />)
                  break;
                case "Bullet_List":
                  dataValue = (<BulletPoint data={JSON.parse(data.value)} userContext={userContext} locationDetails={locationDetails} />)
                  break;
                case "Button_List":
                  dataValue = (<ButtonList key={id} buttons={JSON.parse(data.value)} userOptions={userOptions}
                    disableButtons={prepDay != userContext.MsgID} handleUserResponse={saveUserResponse}
                    handleDeleteResponse={deleteUserResponse} />)
                  break;
                case "description":
                  dataValue = (<Description data={JSON.parse(data.value)} />)
                  break;
                case "Checklist":
                  dataValue = (<CheckList key={id} data={JSON.parse(data.value)} userOptions={userOptions} />)
                  break;
                case "cancel":
                  dataValue = (<Cancel data={JSON.parse(data.value)} />)
                  break;
                case "success":
                  dataValue = (<Success data={JSON.parse(data.value)} />)
                  break;
                case "Single_Graphic":
                  dataValue = (<SingleImage data={JSON.parse(data.value)} />)
                  break;
                case "ResourceLink":
                  dataValue = (<ResourceLink data={JSON.parse(data.value)} />)
                  break;
                case "Dual_Graphic":
                  dataValue = (<DoubleImage data={JSON.parse(data.value)} />)
                  break;
                case "Maps":
                  dataValue = <Maps locationDetails={locationDetails}></Maps>
                  break;
                case "Horizontal_Line":
                  dataValue = <hr></hr>
                  break;
                default:
                  break;
              }
              return dataValue;
            })}
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  )
}

export default Main