import React from "react";
import "../TemplateComponents/helpers/style.css";
const ActionHeader = ({ data }) => {
  const { values } = data;

  return (
    <div className="control_padding parent_container_ah">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div key={ind} className="mb-3">
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={item?.actionCheckImg}
                style={{ height: "auto", width: "25px" }}
              />
              <p
                style={{
                  color: "#17406E",
                  fontSize: "1.3rem",
                }}
              >
                {item?.headerText ? item?.headerText : "Action"}
              </p>
            </div>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: item?.bodyText,
                }}
                style={{
                  color: item?.textColor,
                  fontSize: "20px",
                  lineHeight: "24px",
                  marginTop: "8px",
                  display: (item?.bodyText === "<p><br></p>") && "none"
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ActionHeader;
