export default function appointmentReducer(state = [], action) {
  switch (action.type) {
    case "SET_APPOINTMENT":
      state = { ...state, appointment: action.payload };
      return state;
    case "SET_BOOKED_PHARMACY_APPOINTMENTS":
      state = { ...state, bookedAllAppointment: action.payload };
      return state;
    case 'SET_BOOK_APPOINTMENT':
      state = { ...state, booked: action.payload };
      return state;
    case 'SET_USER_PHARMACY_APPOINTMENT':
      state = { ...state, bookedPharmacyAppt: action.payload };
      return state;
    case 'SET_SUMMARY_INFO':
      state = { ...state, summaryPage: action.payload };
      return state;
      case 'SET_SCHEDULER':
        state = { ...state, scheduler: action.payload };
        return state;
    default:
      return state;
  }
}
