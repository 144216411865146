import React, {useEffect} from "react";
import { decodeToken } from "../../utils";
import { useLocation, Link } from "react-router-dom"; 
import { useDispatch} from "react-redux";
import {
    addFitResp
} from "../../redux/store/fit/fitAction";
import {
    setError,
    setLoader
  } from "../../redux/store/loader/loaderAction";
const Diabetes = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        dispatch(setError(""));
        const token = queryParams.get("token");
        if (!token) {
            dispatch(setError("token is missing. Please contact Admin"));
            dispatch(setLoader(false));
            return;
        } else {
            localStorage.setItem("token", token);
            savePageViewStatus('Y');
        }
    }, []);

    const savePageViewStatus = (resDe) => {
        dispatch(setLoader(true));
        const token = queryParams.get("token");
        const user = decodeToken(token);
        const data = {
            userGuid: user.uid,
            campaignCode: user.scheduleCode,
            responseDe: resDe,
        };
       dispatch(addFitResp(data));
    };

    return (
        <div className="container-fluid g-0">
            <div className="card card-custom card-help">
                <div className="card-header bg-inky text-white text-center">
                    <img alt="logo" className=" w-15" src="../../../Logo.svg" />
                </div>
                <div className="card-body card-body-h-vh bg-lablady p-0 container-340 ">
                    <div className="mt-auto mb-2">
                        <div className="card-thanks text-white card-help-body mb-1 p-2">
                            <p className="mb-3 text-center p_header_text">
                            You are due for an A1c lab exam, aka blood sugar test
                            </p>
                            <p className="mb-3 text-left lbl_a1c_lab" >
                            An A1c Test - Is a blood test that gives an average of a person’s blood sugar levels over the past 2-3 months.
                            </p>
                            <p className="lbl_a1c_lab"><b>How it helps…</b></p>
                            <ul className="ms-0 ps-3 mb-2">
                                <li className="mb-0 ps-2 lbl_a1c_lab">Diagnose diabetes </li>
                                <li className="mb-0 ps-2 lbl_a1c_lab">Manage diabetes by tracking blood sugar changes, especially after new medications or lifestyle changes</li>
                                <li className="mb-0 ps-2 lbl_a1c_lab">Predict complications</li>
                             
                            </ul>
                            <div className="mb-1 p-2 text-center text-white ">
                            <p className="mb-0 text-center presc_a1c_lab">
                            Find your nearest Kaiser Lab location & hours of operation:
                            </p>
                            <span className="mb-0 text-center">
                            <Link className="text-white"  style={{fontSize:"18px"}} to="https://healthy.kaiserpermanente.org/washington/doctors-locations#/simple-form" target="_blank"> KP Labs </Link>
                            </span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Diabetes;
