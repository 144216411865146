import React from 'react'

const ResourceLink = ({ data }) => {
    return (
        <>
            {
                <div className={'single-image d-flex mb-3 align-items-center ' + (data.marginTop ? data.marginTop : "mt-4")}>
                    <p className={'w-40px ' + data.paddingLeft }><img src={"../../../../" + data.Img} alt='img'/></p>
                    <a className='text-inky-blue  ps-2 pt-2' href={'../../../../' + data.Href} target='_blank'>{data.Text}</a>
                </div>
            }
        </>
    )
}

export default ResourceLink