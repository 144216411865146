import React from "react";

const TextGraphic = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <>
            <div key={ind} className="d-flex justify-content-between align-items-center">
              <div
                className="mb-3"
                style={{
                  background: values?.background,
                  height: values?.backgroundHeight,
                  width: "50%",
                }}
              >
                <p style={{ color: item?.color }}>{item?.headerTextValue}</p>
              </div>

              <div
                style={{
                  textAlign: "center",
                  width: "40%",
                }}
              >
                <img
                  src={item?.imageUrl}
                  alt=""
                  height={values?.backgroundHeight}
                  width={values?.backgroundWidth}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default TextGraphic;
