import React from "react";
import "../TemplateComponents/helpers/style.css";

const SubHeader = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding parent_container_sh">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return (
            <div key={ind} className="mb-3">
              <h2
                style={{
                  color: item?.subHeaderColor,
                  // backgroundColor: "#fff",
                  borderRadius: "35px",
                  width: "100%",
                  padding: "5px 0",
                  textAlign: "center",
                  fontSize: "1.3rem",
                }}
              >
                {item?.subHeader}
              </h2>
            </div>
          );
        })}
    </div>
  );
};

export default SubHeader;
