import React, { useEffect } from 'react'
import '../index.css'
const FITKITSummary = () => {
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inky text-white text-center'>
                    <img class=" w-15" src="../../../../Logo.svg" />
                </div>
                <div className='card-body card-body-h-vh bg-fitkit-card-sum p-0 container-340 '>
                    <div className='help-card-bottom help-card-2'>
                        <div className='card-thanks card-help-body'>
                            <p className="mb-0 lbl_medium lh-sm">Thank you - we will get that kit shipped out to you shortly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FITKITSummary