import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';

const Maps = ({ locationDetails }) => {

    const [mapLocationInfo, setMapLocationInfo] = useState("");
    const [mapInfoLocation, setMapInfoLocation] = useState("");
    const [locationCord, setLocationCord] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        let location = "";
        let mapLocation = ""
        if (locationDetails) {
            location = location + locationDetails.FacName;
            mapLocation = "<div class='mb-2 text-inky-blue '><p><strong><h6 class='fs-6'>" + locationDetails.FacName + "</h6></strong></p>";

            location = location + "</br>" + locationDetails.Street;
            location = location + "</br>" + locationDetails.City + " " + locationDetails.State + " " + locationDetails.ZipCode;

            mapLocation = mapLocation + "<span class='text-inky-blue'>" + locationDetails.Street + "</span>";
            mapLocation = mapLocation + "</br><span class='text-inky-blue'>" + locationDetails.City + " " + locationDetails.State + " " + locationDetails.ZipCode + "</span></div>";

            geocodeAddress(location);

            location = location + "</br>" + locationDetails.PhoneNr;
            setMapLocationInfo(location);
            setMapInfoLocation(mapLocation);
        }

    }, [locationDetails]);

    const geocodeAddress = async (address) => {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                address: address,
                key: process.env.REACT_APP_GOOGLE_API_KEY
            }
        });
        if (response.data.status === 'OK') {
            const location = response.data.results[0].geometry.location;
            setLocationCord({ lat: location.lat, lng: location.lng });
        }
    };

    const mapStyles = {
        height: "400px",
        width: "100%",
    };

    const mapContainerStyles = {
        height: "400px",
        width: "100%",
        border: "4px solid #DDDDDD",
        borderRadius: "15px"
    };

    const onMarkerClick = useCallback((location) => {
        setSelected(location);
    }, []);

    const onInfoWindowCloseClick = useCallback(() => {
        setSelected(null);
    }, []);

    return (
        <>
            {
                !locationCord.lat && null
            }

            {
                locationCord.lat &&
                <>
                    <div style={mapStyles} className="mb-2 gmaps">
                        {(
                            <GoogleMap
                                mapContainerStyle={mapContainerStyles}
                                zoom={8}
                                center={{
                                    lat: locationCord.lat, // Default to a fallback coordinate if mammoLocations is empty
                                    lng: locationCord.lng,
                                }}
                            >
                                <Marker
                                    position={{ lat: locationCord.lat, lng: locationCord.lng }}
                                    onClick={() => onMarkerClick(locationCord)}
                                />

                                {selected && (
                                    <InfoWindow
                                        position={{ lat: selected.lat, lng: selected.lng }}
                                        onCloseClick={onInfoWindowCloseClick}
                                    >
                                        <div>
                                            <div dangerouslySetInnerHTML={{__html: mapInfoLocation}}></div>
                                            <a className='f-12 mt-2 fw-normal ' href='javascript:void(0)' onClick={() => window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${locationCord.lat},${locationCord.lng}`)}>Directions</a>                                        </div>
                                    </InfoWindow>
                                )}
                            </GoogleMap>
                        )}
                    </div>
                    <div className='single-image d-flex mt-4 align-items-top'>
                        <p className='w-75 text-inky-blue' dangerouslySetInnerHTML={{ __html: mapLocationInfo }}></p>
                        <p className='w-25 text-end'>
                            <div><img src={"../../../../Directions_blue.svg"} alt='img' className='me-1 w-50 cursor-pointer' 
                            onClick={() => window.open(`https://www.google.com/maps?saddr=My+Location&daddr=${locationCord.lat},${locationCord.lng}`)}/></div>
                            <div className='text-nowrap ps20px'>Directions</div>
                        </p>
                    </div>
                </>
            }
        </>
    )
}

export default Maps