import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import MapItem from "./MapItem";
import MultiLocationMap from "./MultiLocationMap";

const MapComponent = ({ data, handleSave }) => {
  const { values } = data;

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item,) => {
    const marginTop = item?.marginTop || 0;
    const marginBottom = item?.marginBottom || 0;
    const marginLeft = item?.marginLeft || 0;
    const marginRight = item?.marginRight || 0;

    const baseStyles = {
      // position: "absolute",
      position: "relative",
      zIndex: 0,
      height: isFitToScreen ? "600px" : backgroundHeight,
      width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`
    };

    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          transform: "translateY(0)",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      default:
        return baseStyles;
    }
  };

  return (
    <div 
      // className="control_padding"
      >
      {/* {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return(
            <MapItem key={ind} item={item} ind={ind} />
        )})} */}

      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          if(item?.locationSetting === "location list"){
            return (
              <div
                style={{
                  ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, item?.type, false),
                  width: "100%"
                }}
              >
                <MultiLocationMap key={ind} item={item} ind={ind} parentId={data.id} handleSave={handleSave} />
              </div>
              
            )
          }else{
            return (
              <div 
                style={{
                  ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, item?.type, false),
                  width: "100%"
                }}
              >
                <MapItem key={ind} item={item} ind={ind} parentId={data.id} handleSave={handleSave} />
              </div>
              
            )
          }
      })}
    </div>
  );
};

export default MapComponent;
