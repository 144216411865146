import React from 'react'

const Footer = ({paddingLeft}) => {
    return (
        <div className={'pb-4 ' + paddingLeft}>
            <h6 className={'p_header_text text-inky-blue text-start  '}>Have questions? Contact us.</h6>
            <span className='text-inky-blue'>If you have any questions, you can contact us on <a href='tel:+1-877-751-8496'>1-877-751-8496</a></span>
        </div>
    )
}

export default Footer