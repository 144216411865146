import React from "react";

const SingleGraphic = ({ data }) => {
  const { values } = data;
  
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div key={ind} className="d-flex justify-content-center align-items-center mb-3">
            <img
                src={item?.image}
                alt=""
                style={{ width: "90%", height: "auto" }}
              />
          </div>
        ))}
    </div>
  );
};

export default SingleGraphic;
