import React, { useEffect, useState } from 'react';
import "./ColoSummary.css"
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setError,
    setLoader,
    setTokenError,
} from "../../redux/store/loader/loaderAction";
import { decodeToken } from "../../utils";
import Footer from './components/Footer';
import ResourceLink from './components/ResourceLink';
import axios from 'axios'

const ColoSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [msgId, setMsgId] = useState(0);
    const token = queryParams.get("token");
    const [coloPrepActions, setColoPrepActions] = useState([])

    useEffect(() => {
        dispatch(setError(""));
        if (!token) {
            dispatch(setError("token is missing. Please contact Admin"));
            dispatch(setLoader(false));
            return;
        }
        else {
            const data = decodeToken(token);
            setMsgId(data.MsgID);
            getPrepActions();
        }
    }, []);

    const getPrepActions = async () => {
        try {
          const config = {
            headers: {
              "Authorization": `Bearer ${token}`,
            },
          };
          const lkpResp = await axios.get(`${process.env.REACT_APP_OUTREACH_BASE_URL}/api/component/getActionLkp`, config);
          debugger;
          setColoPrepActions(lkpResp.data.prepActions);
        } catch (err) {
          console.error(err);
        }
      }

    const getStepContainerCssClass = (stepContainerId) => {
        if(stepContainerId == 1){
            if(msgId >= 1 && msgId <= 9){
                return ""
            }
            else{
                return "d-none";
            }
        }
        if(stepContainerId == 2){
            if(msgId >= 19 && msgId <= 26){
                return ""
            }
            else{
                return "d-none";
            }
        }
    }

    const getActiveCssClass = (currentId) => {
        if (msgId == currentId) {
            return " active";
        }
        else if (msgId < currentId) {
            return " preview"
        }
        else {
            return " completed";
        }
    }

    const getActionText = (currentId) => {
        if (msgId == currentId) {
            return "Get Started";
        }
        else if (currentId < msgId) {
            return "Completed";
        }
        else {
            return "Preview"
        }
    }

    const redirectToDetailsPage = (currentPage) => {
        window.location = `/kp/colo/details/${currentPage}?token=${token}`;
    }

    return (
        <>
            <div className='container-fluid g-0'>
                <div className='card card-custom card-help'>
                    <div className='card-header bg-blue text-white text-center'>
                        <img class=" w-15" src="../../../Logo.svg" />
                    </div>
                    <div className="container-fluid">
                        <div className="steps-page-heading mb-4">
                            <h5 className="p_header_text text-inky-blue text-start mt-4">Prepare</h5>
                            <p className="text-inky-blue fs-6">This is your electronic guide to preparing for your upcoming colonoscopy. It will support and guide you in the coming weeks so you can be as prepared as possible.</p>
                        </div>
                        <div className="stepper-size-320">
                            <div className={"stepper-conatainer "}>
                                {
                                    coloPrepActions.map((item, index) => {
                                        return <div className={"steps-group " + getActiveCssClass(item.messageId)} key={index}>
                                            <div className="steps-circle">{index+1}</div>
                                            <div className="d-flex flex-wrap flex-column steps-details-list">
                                                <h6 className="stepper-label text-inky-blue fw-bold">{item.actionText}</h6>
                                                <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(item.messageId)}>{getActionText(item.messageId)}</button>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <hr></hr>
                        <div className='marginBottom25Rem'>
                            <h6 className='p_header_text text-inky-blue text-start paddingLeft15px'>Additional Resources</h6>
                            <ResourceLink data={{ "marginTop":"mt-3", "paddingLeft":"marginLeft9px","Img": "Apple.svg", "Href": "FAQs.pdf", "Text": "Frequently asked questions" }} />
                            <ResourceLink data={{ "marginTop":"mt-0", "paddingLeft":"marginLeft9px","Img": "Apple.svg", "Href": "DiabetesMedsGuide.pdf", "Text": "Diabetes medication questions" }} />
                        </div>
                        <Footer paddingLeft="paddingLeft15px"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColoSummary