import React, { useEffect, useState } from 'react'
import './index.css'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment';
const Home = () => {
  return (
    <div className='container home-menu'>
       <div className='img-container'>
      <img src="/Artboard 3.svg" className="img-fluid" style={{ width: '200px' }} />
    </div>
      <div className='row align-items-center'>
        <div className='col-md-5 col-sm-12  '>
          <img className='main-img px-2'  src="Artboard 2.svg"></img>
        </div>
        <div className='col-md-7 col-sm-12  bg-white text-blue parent-elxo'>
          <p className='mt-2 p_header_text'>Hello! You have arrived at the page we use for our applications</p>
          <p className="mb-0 normal_text_1 text-center">Elxo helps organizations plot their best path down the bumpy road of complex
            patient care, bringing their processes and patient connection problems to
            solve. We draw the map to help patients more empowered, work - flows smoother and staff happier.</p>
         
          <p className="fw-medium normal_text_1   mt-4 text-center">Find out more at:</p>

          <div className="container  normal_text_1  text-center">
            <a href="https://www.elxoinc.com/" className="fw-medium normal_text_1 d-inline-block text-blue ">www.elxoinc.com</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home