import React from "react";
import "../TemplateComponents/helpers/style.css";

const ButtonForm = ({ data, handleSave, handleBtnNavigate, previewData  }) => {
  const { values } = data;
  const handleButtonClick = (item) => {
    const stringId = String(item?.id)
    if( item?.buttonType === "text" &&  item?.nextPage !== ""){
      handleBtnNavigate(item?.nextPage)
      // handleSave(
      //   {
      //     parentId: data?.id,
      //     id: stringId,
      //     clicked: "N",
      //   },
      //   data.type
      // );
    }else if ( item?.buttonType === "link") {
      handleSave(
        {
          parentId: data?.id,
          id: stringId,
          clicked: "Y",
          result: "Y"
        },
        data.type,
        false
      );
      if(item?.buttonLink !== ""){
        let navigateUrl = item?.buttonLink
        if (!/^https?:\/\//i.test(navigateUrl)) {
          navigateUrl = 'https://' + navigateUrl;
        }
        window.open(navigateUrl, "_blank");
      }
      
    } 
  };

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item,) => {
    const baseStyles = {
      // position: "absolute",
      position: "relative",
      zIndex: 0,
      height: isFitToScreen ? "600px" : backgroundHeight,
      width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
    };

    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          transform: "translateY(0)",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      default:
        return baseStyles;
    }
  };

  return (
    <div 
      // className="control_padding"
      >
      <div>
        {values &&
          values.length > 0 &&
          values?.map((item, ind) => (
            <button
               onClick={() => handleButtonClick(item)}
              className="btn_form_btn"
              style={{
                ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item,),
                borderRadius: item?.buttonRounding ? `${item.buttonRounding}px` : "0px",
                opacity: item?.opacity,
                border: item?.borderColor ? `1px solid ${item?.borderColor}` : "#000",
                height: item?.ySize === "px" ? "auto" : `${item?.ySize}`,
                width: item?.xSize === "px" ? "auto" : `${item?.xSize}`,
                background: item?.background,
                color:item?.buttonFontColor
              }
              }
              key={ind}
            >
              {item?.text ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.text,
                  }}
                  style={{
                    fontFamily: item?.buttonFont,
                    fontSize: item?.buttonFontSize,
                    paddingLeft: item?.paddingLeft ? `${item.paddingLeft}px` : "",
                    paddingRight: item?.paddingRight ? `${item.paddingRight}px` : "",
                    paddingTop: item?.paddingTop ? `${item.paddingTop}px` : "",
                    paddingBottom: item?.paddingBottom ? `${item.paddingBottom}px` : "",
                    color:item?.buttonFontColor
                  }}
                  className="editor-inner-text"
                />
              ) : (
                "Button"
              )}
            </button>
          ))}
      </div>
    </div>
  );
};

export default ButtonForm;
