export default function fitReducer(state = [], action) {
    switch (action.type) {
        case "SET_FIT_RESP":
            state = { ...state, fit: action.payload };
            return state;
        case "SET_FIT_CONT_RESP":
            state = { ...state, fitcontent: action.payload };
            return state;
        default:
            return state;
    }
}