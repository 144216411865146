import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
const MamoMessage = () => {
  const { company, campaignId } = useParams();

  
  const handleCallClick = () => {
    window.location.href = `tel:+1${"877-751-4324"}`;
  }

  return (
    <div className='container-fluid g-0'>
      <div className='card card-custom card-help'>
        <div className='card-header bg-inky text-white text-center'>
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
        <div className='card-body card-body-h-vh bg-mamo-lady p-0 container-340'>
          <div className="mt-auto mb-2">
            <div className='card-thanks text-white card-help-body p-1 mb-5'>
             <p className="mb-1 normal_text fw-bold px-2 text-center">
             Please contact your doctor to learn more about screening locations.</p>

               <p className="mb-1 normal_text fw-bold px-2 text-center">
               You can also schedule at a Kaiser Permanente center if distance is not an issue: <span style={{textDecoration: "underline"}} onClick={() => handleCallClick()}> 877-751-4324.</span>
               </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-3 mt-5">
              <div className="d-flex justify-content-between mr-2 navigation_img_wrapper">
                <Link to={`/${company}/${campaignId}/mammo/summary`}>
                  <img
                    className="me-4 navigation_img"
                    src="/back_white.svg"
                    height="40"
                    width="100"
                    alt="Back Dark"
                  />
                </Link>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default MamoMessage;

