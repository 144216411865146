import React from "react";

const Text = ({ data }) => {
  const { values } = data;

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item, ) => {
    const marginTop = item?.marginTop || 0;
    const marginBottom = item?.marginBottom || 0;
    const marginLeft = item?.marginLeft || 0;
    const marginRight = item?.marginRight || 0;

    const baseStyles = {
      // position: "absolute",
      position: "relative",
      zIndex: 0,
      // height: isFitToScreen ? "600px" : backgroundHeight,
      // width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`
    };

    switch (align) {
      case "top left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "top center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "top right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "middle left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          transform: "translateY(0)",
        };
      }
      case "middle center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "middle right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "bottom left": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
        };
      }
      case "bottom center": {
        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "bottom right": {
        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          textAlign: "right"
        };
      }
      case "left": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
        };
      }
      case "center": {
        return {
          ...baseStyles,
          top: 0,
          left: 0,
          textAlign: "center"
        };
      }
      case "right": {
        return {
          ...baseStyles,
          top: 0,
          right: 0,
          textAlign: "right"
        };
      }
      default:
        return baseStyles;
    }
  };


  return (
    <div 
      // style={{margin:10}} 
      // className="control_padding"
      >
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return (
          <div
            key={ind}
            // style={{
            //   height: item?.textHeight,
            //   width: item?.textWidth,
            //   background: item?.textBackground,
            //   border: `1px solid ${item?.textBorder}`,
            //   color: item?.textColor,
            //   opacity: item?.textOpacity,
            //   // marginTop: "5px",
            //   padding: item?.textBackground ? "7px" : "0px",
            //   marginBottom: item?.marginBottom ? item?.marginBottom :  "10px",
            //   marginTop: item?.marginTop ,
            //   marginLeft: item?.marginLeft ,
            //   marginRight: item?.marginRight ,
            //   fontFamily: item?.textFont,
            //   fontSize: item?.fontSize,
            //   paddingTop: item?.paddingTop,
            //   paddingLeft: item?.paddingLeft,
            //   paddingBottom: item?.paddingBottom,
            //   paddingRight: item?.paddingRight,
            //   borderRadius: item?.textBorderRadius ? item?.textBorderRadius : "7px",
            //   position: item?.textXPosition ? "absolute" : "relative",
            //   top: item?.textXPosition ? item?.textXPosition : "10",
            //   [item?.textAlign || "left"]: item?.textYPosition
            //     ? item?.textYPosition
            //     : "0px",
            //   wordWrap: "break-word",
            //   // padding: "4px",
            // }}
            style={{
              ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, ),
                                    background: item?.textBackground,
                                    borderRadius: item?.textBorderRadius ? `${item.textBorderRadius}px` : "",
                                    border: `1px solid ${item?.textBorder}`,
                                    color: item?.textColor,
                                    opacity: item?.textOpacity,
                                    fontFamily: item?.textFont,
                                    fontSize: item?.fontSize ? `${item.fontSize}` : "",
                                    height: item.textHeight,
                                    width: "100%",
                                    wordWrap: "break-word",

            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: item?.bodyText || "Text",
              }}
              className="editor-inner-text"
              // style={{
              //   margin: "4px 8px",
              //   opacity: item?.textOpacity,
              //   textAlign: item?.textAlign,
              //   color: item?.textColor,
              // }}
              style={{
              // margin: "4px 8px",
              opacity: item?.textOpacity,
              // textAlign: item?.textAlign,
              paddingLeft: item?.paddingLeft ? `${item.paddingLeft}px` : "",
              paddingRight: item?.paddingRight ? `${item.paddingRight}px` : "",
              paddingTop: item?.paddingTop ? `${item.paddingTop}px` : "",
              paddingBottom: item?.paddingBottom ? `${item.paddingBottom}px` : "",
              color: item?.textColor,
              fontSize: item?.fontSize ? `${item.fontSize}` : ""
            }}
            />
          </div>
        )
      })}
    </div>
  );
};

export default Text;
