import React from 'react'
import './index.css'
import { useSelector } from 'react-redux'
import { selectBookedAppointment } from '../redux/store/appointment/appointmentSelector'
import moment from 'moment'

const AppointmentSummary = () => {
    const bookedAppointment = useSelector(selectBookedAppointment);
    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
            <div className='card-header bg-blue text-white text-center'>
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
                <div className='card-body card-body-h-vh bg-card p-0 container-340 '>
                    <div className='help-card-bottom'>
                        <div className='bg-blue text-white card-help-body'>
                            <p className="mb-0">Helping you live your best life is our 
                                top priority, thank you for giving us 
                                the opportunity to be a part of it. We're here to support you and 
                                your needs every step of the way</p>
                            <p className="fw-medium  mt-3">You will receive a call from us on:</p>
                            <p className="fw-semibold  mt-3 fs-5 pb-0 mb-0">{moment(bookedAppointment?.date).format('dddd Do MMMM')} <br /> at {moment(bookedAppointment?.date).utc().format('LT')} by {bookedAppointment.APPT_TYPE_CD}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentSummary