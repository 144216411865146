export default function loaderReducer(state = [], action) {
    switch (action.type) {
        case "SET_LOADER":
            console.info("Loading", action.payload)
            state = { ...state, loading: action.payload };
            return state;
        case "SET_ERROR":
            console.info("Error", action.payload)
            state = { ...state, error: action.payload };
            return state;
        case "SET_TOKEN_ERROR":
            console.info("Error", action.payload)
            state = { ...state, tokenError: action.payload };
            return state;
        default:
            return state;
    }
}
