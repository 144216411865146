import React from 'react'

const BulletPoint = ({ data, userContext, locationDetails }) => {

  let checkinApptTime = "", checkIn2ApptTime = "";
  if (userContext?.ApptTM) {
    let apptTime = userContext?.ApptTM;
    apptTime = apptTime.replace('a.m', 'AM');
    apptTime = apptTime.replace('p.m', 'PM')
    let newdate = new Date('July 31, 2022 ' + apptTime);
    debugger;
    
    let difference = newdate.getMinutes() - 40;
    newdate.setMinutes(difference);
    checkinApptTime = newdate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    difference = newdate.getMinutes() - 120;
    newdate.setMinutes(difference);
    checkIn2ApptTime = newdate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }
  
  data.map(x => {
    x.Text = x.Text.replace("#CheckInApptTime#", checkinApptTime);
    x.Text = x.Text.replace("#CheckIn2ApptTime#", checkIn2ApptTime);
    if(locationDetails){
      x.Text = x.Text.replace("#FacPhoneNr#", locationDetails.PhoneNr);
    }
  })
  return (
    <div className='bullet-point text-inky-blue'>
      <ul className='mb-0 ps-3'>
        {
          data.map((item) => {
            return (
              <li className='mt-2'>
                <span dangerouslySetInnerHTML={{ __html: item.Text }}></span>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default BulletPoint