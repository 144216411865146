import React from "react";

const StayEnrolled = () => {
    return(
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
            <div className='card-header bg-inky text-white text-center'>
          <img className=" w-15" src="../../Logo.svg" />
        </div>
                <div className='card-body card-body-h-vh bg-card3 p-0 container-340 '>
                    <div className="mt-auto mb-5">
                        <div className='bg-white text-inky card-help-body mb-3 cancel_enrollment opicity-5'>
                            <p className="mb-0 text-center normal_text">No problem! We will keep 
                                you enrolled, and 
                                will contact you later in
                                the year. If you change
                                your mind, call us at
                                <span className="d-block w-100 normal_text">1-866-380-2588.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default StayEnrolled;