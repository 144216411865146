import { createSelector } from 'reselect';

const fitReducer = (state) => state.fit

export const selectFitResponse = createSelector(
    [fitReducer],
    (fit) => fit.fit
);

export const selectReminderContent = createSelector(
    [fitReducer],
    (fitcontent) => fitcontent.fitcontent
);