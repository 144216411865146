import React from 'react'

const SingleImage = ({ data }) => {
  return (
    <>
      {
        data.Text ? <div className='single-image d-flex mt-4 align-items-center'>
          <p className={(data.TextWidth ? data.TextWidth : 'w-75 ') + ' text-inky-blue'}>{data.Text}</p>
          <p className={(data.ImageWidth ? data.ImageWidth : 'w-25 ')}><img src={"../../../../" + data.Img} alt='img'  className='w-75' /></p>
        </div> :
          <div className={'d-flex mt-4 ' + data.ImgCssClass}>
            <img src={"../../../../" + data.Img} alt='img' className='w-100' />
          </div>
      }
    </>
  )
}

export default SingleImage