import React, { useEffect, useState } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse } from "../../redux/store/fit/fitSelector";
import {
  addFitResp,
  getFitResponseAction,
} from "../../redux/store/fit/fitAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import { error } from "../../redux/store/loader/loaderSelector";
import { decodeToken } from "../../utils";

const FITKIT = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fitResponse = useSelector(selectFitResponse);
  const errorMessage = useSelector(error);
  const queryParams = new URLSearchParams(location.search);
  const { company, campaignId } = useParams();
  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));
    }
  }, []);

  useEffect(() => {
    if (fitResponse) {
      dispatch(setLoader(false));
      if (fitResponse.responseDe === "Y") {
        navigate(`/${company}/${campaignId}/fit/summary`);
      } else if (
        fitResponse?.responseDe === null &&
        fitResponse.responseDe !== "N"
      ) {
        handleSendFITKit("N");
      }
    }
  }, [fitResponse]);

  const handleSendFITKit = (resDe) => {
    dispatch(setLoader(true));
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
    };
    dispatch(addFitResp(data));
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-fit-card2 bg-fit-card-one p-0 container-340 ">
          <div className="mt-auto mb-4">
            <div className="card-thanks text-white card-help-body mb-1 p-1">
              <p className="mb-0 text-center lbl_medium">
                Please complete the FIT kit we previously sent. This screens for
                colon cancer, and needs to be repeated yearly to work. It takes
                just a few minutes and  <span style={{textDecoration: "underline"}}>can save your life.</span>
              </p>

              <p className="text-center mb-0 mt-2 lbl_medium">
                If you still have your kit, make sure the expiration date is at
                least one month away.
              </p>
              <img
                src="/FITKIT.svg"
                alt="FITKIT Logo"
                style={{ height: "60px", width: "100%" }}
              />

              <p className="text-center mb-0 lbl_medium">
                If not (or if the previous kit is lost), click the button below
                and we can mail you a new kit in the next two weeks. Questions? Please contact your 
                primary care provider.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div
                className="btn_global"
                onClick={() => handleSendFITKit("Y")}
              >
                <span className="mb-0">
                  Send my FIT kit
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FITKIT;
