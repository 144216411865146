import React, { useEffect, useState, useRef } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse,  selectReminderContent } from "../../redux/store/fit/fitSelector";
import {
  addFitResp,
  getFitResponseAction,
  getFitContentAction
} from "../../redux/store/fit/fitAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
// import PlayIcon from "../../assets/images/playIcon.png";
import { decodeToken } from "../../utils";

const FitHispanic = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const campVideoRef = useRef(null);
  const fitResponse = useSelector(selectFitResponse);
  const fitContent = useSelector(selectReminderContent);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      let language = data.Langwritten === "Spanish" || data.Langspoken === "Spanish" ? "Spanish" : "English";

      dispatch(getFitContentAction({language: language, campCode: data.campCode }))
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));

      if (fitResponse === null || fitResponse?.watchVideo !== "Y") {
         handleSendFITKit("Y","N");
      }
    }
  }, []);
 
  const handleSendFITKit = (resDe, viewvideo) => {
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe
    };
    dispatch(addFitResp(data));
  };

  const bindText = (textSequenceId) => {
    let item = fitContent?.find(
      (x) => x.textSequenceId === textSequenceId
    )?.text;
    return item;
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-fit-hispanic bg-fit-card-one p-0 container-340 ">
          <div className="fit_af_am">
            <div className="d-flex text-left mt-1 mb-5" style={{width:"157px"}}>
              <span className="fit-hispanic-dyk">
              {bindText(1)}
              </span>
            </div>
            <div >
              <div className="d-flex justify-content-center mt-0">
                <div className="card-thanks text-white card-help-body mb-0 p-3" >
                  <p className="mb-0 text-left lbl_medium">
                  {bindText(2)}
                  </p>
                  <p className="text-center mb-0 mt-3 lbl_medium">
                    {bindText(3)}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-1">
                <div className="">
                  <p className="text-center mb-0 lbl_medium p-3 fit_question">
                  {bindText(4)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitHispanic;
