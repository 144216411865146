import { createSelector } from 'reselect';

const loaderReducer = (state) => state.loader

export const loading = createSelector(
    [loaderReducer],
    (loader) => loader.loading
);

export const error = createSelector(
    [loaderReducer],
    (loader) => loader.error
);

export const tokenError = createSelector(
    [loaderReducer],
    (loader) => loader.tokenError
);