import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ErrorModal from './errorModal';

const ButtonList = ({ buttons, userOptions, disableButtons, handleUserResponse, handleDeleteResponse }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [user, setUser] = useState();
    const [error, setError] = useState();
    const [open, setOpen] = useState(false)
    const token = searchParams.get('token');
    const [buttonList, setButtonList] = useState([]);
    
    const onClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(userOptions && buttons){
          let options = JSON.parse(JSON.stringify(userOptions));
          setUserSelectedOptions(options);  
        }
        else if(buttons){
            setButtonList(buttons);
        }
    },[buttons, userOptions])

    const setUserSelectedOptions = (userResponses) => {
        let btnList = JSON.parse(JSON.stringify(buttons));
        userResponses.map(x => {
            if(x.QstId == buttons.QstId){
                btnList.Options.map(k => {
                    if(k.Code == x.UserRespCode){
                        k.userSelectedOption = true;
                    }
                })             
            }
        })
        setButtonList(btnList);
    }

    const saveUserResponse = async (qstId, respCode) => {
        handleUserResponse(qstId, respCode);
    }

    const deleteUserResponse = async (qstId, respCode) => {
        handleDeleteResponse(qstId, respCode);
    }

    return (
        <div className='button-list-main text-blue'>
            {buttonList?.Options?.map((button, index) => (
                <button disabled={disableButtons} key={index} 
                className={
                    (button.userSelectedOption == true ? 'selected ' : 'button-list ') +
                    (disableButtons ? 'inactiveButton ' : ' ')
                } 
                onClick={() => button.userSelectedOption == true ? deleteUserResponse(buttons?.QstId, button.Code) : saveUserResponse(buttons?.QstId, button.Code)}>
                    {button?.Value}
                </button>
            ))}
            {error && <ErrorModal message={error} isOpen={open} onClose={onClose} />}
        </div>
    );
};

export default ButtonList;
