export default function mtmReducer(state = [], action) {
    switch (action.type) {
        case "SET_MTM_RESP":
            state = { ...state, mtm: action.payload };
            return state;
        case "SET_MTM_SURVEY_OPTIONS":
            state = { ...state, mtmOptions: action.payload };
            return state;
        case "SET_PAGE_LOCALIZED_DATA":
            state = { ...state, mtmLocalizedData: action.payload };
            return state;
        default:
            return state;
    }
}