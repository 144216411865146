import React from "react";

const Question = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      {/* {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div
            className="mb-3"
            style={{
              marginBottom: "5px",
              position: item?.xPosition ? "absolute" : "relative",
              top: item?.xPosition ? item?.xPosition : "10px",
            }}
            key={ind}
          >
            <div
              style={{
                [item?.align || "left"]: item?.yPosition
                  ? item?.yPosition
                  : "0px",
                marginBottom: "10px",
                color: "#3e9fd4",
              }}
            >
              {item?.text ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.text,
                  }}
                  className="editor-inner-text"
                />
              ) : (
                "Question"
              )}
            </div>
            {item?.isShowTextbox && (
              <input
                style={{
                  borderColor: item?.textboxBorderColor,
                  background: item?.textboxBackgroundColor,
                  opacity: item?.textboxOpacity,
                  marginBottom: "15px",
                }}
              />
            )}
            <div className="position-relative">
              {item?.buttons &&
                item?.buttons?.length > 0 &&
                item?.buttons?.map((btnItem, btnIndex) => {
                  return (
                    <div key={btnIndex}> 
                      <button
                        style={{
                          border: "1px solid",
                          borderColor: btnItem?.button_borderColor
                            ? btnItem?.button_borderColor
                            : "#3e9fd4",
                          background: btnItem?.button_Background
                            ? btnItem?.button_Background
                            : "transparent",
                          color: btnItem?.button_Background
                            ? "#000"
                            : "#3e9fd4",
                          opacity: btnItem?.button_opacity,
                          padding: "4px 25px",
                          position: btnItem?.xQuestionButton_Position
                            ? "absolute"
                            : "relative",
                          top: btnItem?.xQuestionButton_Position
                            ? btnItem?.xQuestionButton_Position
                            : "0px",
                          [btnItem?.button_Align || "left"]:
                            btnItem?.yQuestionButton_Position
                              ? btnItem?.yQuestionButton_Position
                              : "0px",
                          fontFamily:
                          //  globalSettingVal?.[
                          //   `button_${ind + 1}_font`
                          // ]
                          //   ? globalSettingVal[`button_${ind + 1}_font`]
                          //   : 
                            "inherit",
                          borderRadius:
                          //  globalSettingVal?.[
                          //   `button_${ind + 1}_rounding`
                          // ]
                          //   ? globalSettingVal[`button_${ind + 1}_rounding`]
                          //   :
                             "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {btnItem?.button_Text ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: btnItem?.button_Text,
                            }}
                            style={{
                              fontSize:
                                // globalSettingVal?.buttonFontSize
                                //   ? globalSettingVal.buttonFontSize
                                values?.buttonFontSize,
                            }}
                            className="editor-inner-text"
                          />
                        ) : (
                          "Button"
                        )}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        ))} */}
        {values &&
                            values.length > 0 &&
                            values?.map((item, ind) => (
                              <div
                                className="mb-3"
                                style={{
                                  marginBottom: "5px",
                                  position: item?.xPosition
                                    ? "absolute"
                                    : "relative",
                                  top: item?.xPosition ? item.xPosition : "10",
                                  // position: (item?.xPosition === "px" || !item?.xPosition)
                                  //   ? "relative"
                                  //   : "absolute",
                                  // top: (item?.xPosition && item?.textXPosition != "px") ? item.xPosition : "10",
                                  // marginTop: item?.align === "top" ? "-17px"
                                  //   : item?.align === "middle" ? "0px"
                                  //     : item?.align === "bottom" ? "37px"
                                  //       : "0px",
                                  // minHeight: "72px",
                                }}
                                key={ind}
                              >
                                <div
                                  style={{
                                    [item?.align || "left"]: (item?.yPosition && item?.textXPosition != "px")
                                      ? item.yPosition
                                      : "0px",
                                    marginBottom: "10px",
                                    color: "#3e9fd4",
                                  }}
                                >
                                  {item?.text ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.text,
                                      }}
                                      className="editor-inner-text"
                                    />
                                  ) : (
                                    "Question"
                                  )}
                                </div>
                                {item?.isShowTextbox && (
                                  <input
                                    style={{
                                      borderColor: item?.textboxBorderColor,
                                      background: item?.textboxBackgroundColor,
                                      opacity: item?.textboxOpacity,
                                      marginBottom: "15px",
                                    }}
                                  />
                                )}
                                <div className="position-relative">
                                  {item.buttons &&
                                    item.buttons.length > 0 &&
                                    item.buttons.map((btnItem, btnIndex) => {
                                      return (
                                        <div>
                                          <button
                                            style={{
                                              border: "1px solid",
                                              borderColor:
                                                btnItem?.button_borderColor
                                                  ? btnItem.button_borderColor
                                                  : "#3e9fd4",
                                              background:
                                                btnItem?.button_Background
                                                  ? btnItem.button_Background
                                                  : "transparent",
                                              color: btnItem?.button_Background
                                                ? "#000"
                                                : "#3e9fd4",
                                              opacity: btnItem?.button_opacity,
                                              padding: "4px 25px",
                                              // position:
                                              //   btnItem?.xQuestionButton_Position
                                              //     ? "absolute"
                                              //     : "relative",
                                              position: (item?.xQuestionButton_Position === "px" || !item?.xQuestionButton_Position) || (item?.yQuestionButton_Position === "px" || !item?.yQuestionButton_Position)
                                                ? "relative"
                                                : "absolute",
                                              top: (btnItem?.xQuestionButton_Position && item?.xQuestionButton_Position != "px")
                                                ? btnItem.xQuestionButton_Position
                                                : "0px",
                                              [btnItem?.button_Align || "left"]:
                                                (btnItem?.yQuestionButton_Position && item?.yQuestionButton_Position != "px")
                                                  ? btnItem.yQuestionButton_Position
                                                  : "0px",
                                              fontFamily: 
                                              // globalSettingVal?.[
                                              //   `button_${ind + 1}_font`
                                              // ]
                                              //   ? globalSettingVal[
                                              //   `button_${ind + 1}_font`
                                              //   ]
                                              //   : 
                                                "inherit",
                                              // borderRadius:
                                              //  globalSettingVal?.[
                                              //   `button_${ind + 1}_rounding`
                                              // ]
                                              //   ? globalSettingVal[
                                              //   `button_${ind + 1}_rounding`
                                              //   ]
                                              //   : "35px",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            {btnItem?.button_Text ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: btnItem.button_Text,
                                                }}
                                                style={{
                                                  fontSize:
                                                    // globalSettingVal?.buttonFontSize
                                                    //   ? globalSettingVal.buttonFontSize
                                                    //   :
                                                       values.buttonFontSize,
                                                }}
                                                className="editor-inner-text"
                                              />
                                            ) : (
                                              "Button"
                                            )}
                                          </button>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            ))}
    </div>
  );
};

export default Question;
