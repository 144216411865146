import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";
import { setMammoFacLocation} from "./mammoAction";

export function* getMammoFacLocationAction(data) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    const mamLocationResponse = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetApptLocations?locType=${data.payload.type}`, config);
    yield put(setMammoFacLocation(mamLocationResponse.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* onGetMammoFacLocations() {
  yield takeLatest("GET_MAM_FAC_LOC_RESP", getMammoFacLocationAction);
}

export function* mammoSagas() {
  yield all([call(onGetMammoFacLocations)]);
}
